
import {defineComponent, PropType} from 'vue'
import Icon from '@/components/Icon.vue';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
    name: 'CompetenceDescriptionStand',
    components: {Icon},
    props: {
        competence: {type: Object as PropType<CompetenceModel>, required: true}
    },
    data() {
        return {
            collapseOpen: true,
        }
    },
    methods: {
        infoCollapse() {
            this.collapseOpen = !this.collapseOpen;
        }
    }
})
