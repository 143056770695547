<template>
    <VueCookieAcceptDecline
        :disable-decline="false"
        :show-postpone-button="false"
        element-id="cookieBanner"
        position="bottom-left"
        transition-name="slideFromBottom"
        type="floating"
        @clicked-accept="cookieClickedAccept"
    >
        <template #message>
          <div class="h6 text-center">Beheer cookie toestemming</div>
          Wij gebruiken functionele en analytische cookies om ons beheer te analyseren.
          Zo krijgen we beter inzicht in het functioneren van de website.<br>
          Door op ‘Accepteren’ te klikken ga je akkoord met het plaatsen van deze cookies.<br>
          <div class="text-center mt-2">
            <a href="https://www.vo-raad.nl/over-deze-website">Privacyverklaring & cookies</a>
          </div>
        </template>

        <template #declineContent class="w-100 btn btn-secondary">Weigeren</template>

        <template #acceptContent class="w-100 btn btn-primary">Accepteren</template>
    </VueCookieAcceptDecline>
</template>

<script lang="ts">
// @ts-ignore
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import {defineComponent} from 'vue';
import {useGtm} from '@gtm-support/vue-gtm';

export default defineComponent({
    name: 'CookieBanner',
    components: {VueCookieAcceptDecline},
    setup() {
        return {
            cookieClickedAccept() {
                useGtm()?.enable();
            }
        };
    },
})
</script>