import {defineComponent} from 'vue';
import {routeIndexOf} from '@/data/guardHelper';
import {LocationAsRelativeRaw} from 'vue-router';

export const navigationMixin = defineComponent({
    computed: {
        stepIndex(): number {
            if (this.$route.name) {
                return routeIndexOf({name: this.$route.name, params: this.$route.params}, this.$store.state.steps);
            }

            console.error('Unnamed route', this.$store.state.steps);
            return -1;
        },
        previousPage(): LocationAsRelativeRaw|null {
            return (this.stepIndex > 0) ? this.$store.state.steps[this.stepIndex - 1] : null;
        },
        nextPage(): LocationAsRelativeRaw|null {
            return (this.stepIndex + 1 < this.$store.state.steps.length) ? this.$store.state.steps[this.stepIndex + 1] : null;
        }
    }
});