<template>
    <div class="return-button">
        <survey-navigation-buttons :forwards="false" />
    </div>

    <section v-if="competence" class="block-wrapper">
        <div class="content-wrapper-sm">
            <div class="title-width-dot d-flex justify-content-start align-items-start mb-3">
                <span class="dot mr-3 mb-2" :style="{ backgroundColor: competence.menuColor }" />
                <div class="title mb-2">
                    <h3 ref="title">{{ competence.name }}</h3>
                </div>
            </div>
        </div>

        <competence-description-stand :competence="competence" />

        <div class="rating-question-list pt-5">
            <rating-question v-for="(statement, key) in competence.statements" :key="key" :statement="statement" />

            <survey-navigation-buttons class="d-flex flex-nowrap justify-content-end align-items-center" :backwards="false" />
        </div>
    </section>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import CompetenceDescriptionStand from '@/components/CompetenceDescriptionStand.vue';
import RatingQuestion from '@/components/form/RatingQuestion.vue';
import {navigationMixin} from '@/router/navigationMixin';
import SurveyNavigationButtons from '@/components/SurveyNavigationButtons.vue';
import {competenceMixin} from '@/router/competenceMixin';
import {mobileHeaderHeight} from '@/data/constants';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'CompetenceQuestions',
    components: {SurveyNavigationButtons, CompetenceDescriptionStand, RatingQuestion, ProgressSpinner},
    mixins: [navigationMixin, competenceMixin],
	updated() {
		if (this.$refs.title instanceof HTMLElement) {
			const y = this.$refs.title.getBoundingClientRect().top + window.pageYOffset - mobileHeaderHeight;
			window.scrollTo({top: y, behavior: 'smooth'});
		}
	}
})
</script>