import {LocationAsRelativeRaw} from 'vue-router';
import {Routes} from '@/router';

export const isKeyOf = <T>(key: string|number|symbol, obj: T): key is keyof T => key in obj;

export const isSameRoute = (a: LocationAsRelativeRaw, b: LocationAsRelativeRaw) => {
    const aParams = a.params ?? {};
    const bParams = b.params ?? {};
    return a.name === b.name && JSON.stringify(aParams) === JSON.stringify(bParams);
}

/** Returns passed query strings as a string array or null if none are set */
export const getQueryStrings = (value: string|(string|null)[]|null): null|string[] => {
    if (value === null || value === undefined) {
        return null;
    }

    if (!Array.isArray(value)) {
        return [value];
    }

    const values = [];
    for (const val of value) {
        if (val) {
            values.push(val);
        }
    }

    return values;
}

export const getQueryString = (value: string|(string|null)[]|null) => {
    const strings = getQueryStrings(value);
    return strings ? strings[0] : null;
}

export const getLatestStepPage = (storeSteps: LocationAsRelativeRaw[]) => {
    const finalPage = {name: Routes.ResultOverview};
    if (isRouteIn(finalPage, storeSteps)) {
        return finalPage;
    }

    return storeSteps[storeSteps.length - 1];
}

export const routeIndexOf = (location: LocationAsRelativeRaw, locations: LocationAsRelativeRaw[]) => {
    for (let i = 0; i < locations.length; i++) {
        if (isSameRoute(location, locations[i])) {
            return i;
        }
    }

    return -1;
}

export const isRouteIn = (needle: LocationAsRelativeRaw, haystack: LocationAsRelativeRaw[]) => routeIndexOf(needle, haystack) >= 0;