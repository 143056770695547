import {CommitOptions, createStore, DispatchOptions, Store, Store as VuexStore} from 'vuex';
import {mutations, Mutations} from '@/store/mutations';
import {state, State} from '@/store/state';
import {actions, Actions} from '@/store/actions';
import {getters, Getters} from '@/store/getters';
import {InjectionKey} from 'vue';

export const vuexStoreKey: InjectionKey<Store<State>> = Symbol();

export default createStore({state, mutations, actions, getters})

export type StoreType = Omit<VuexStore<State>, 'getters' | 'commit' | 'dispatch'> & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
        key: K,
        payload?: P,
        options?: CommitOptions
    ): ReturnType<Mutations[K]>
} & {
    dispatch<K extends keyof Actions>(
        key: K,
        payload?: Parameters<Actions[K]>[1],
        options?: DispatchOptions
    ): ReturnType<Actions[K]>
} & {
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>
    }
}
