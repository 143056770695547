
import {defineComponent, ref} from 'vue';
import SurveyNavigationButtons from '@/components/SurveyNavigationButtons.vue';
import Icon from '@/components/Icon.vue';
import BaseModal from '@/components/BaseModal.vue';
import RatingSymbol from '@/components/RatingSymbol.vue';
import RadarChart from '@/components/chartsJs/RadarChart.vue';
import {useStore} from 'vuex';
import {vuexStoreKey} from '@/store';
import {CompetenceModel} from '@/data/CompetenceModel';
import api from '@/data/api';
import RatingQuestion from '@/components/form/RatingQuestion.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'ResultOverview',
    components: {RatingQuestion, RatingSymbol, BaseModal, Icon, SurveyNavigationButtons, RadarChart, ProgressSpinner},
    setup() {
        const store = useStore(vuexStoreKey);
        const ratings = ref<number[][]>([[], [], [], []]);
        const modalCompetence = ref<CompetenceModel>();
        const currentTab = ref(1);
        const printButtonLabel = ref('Print');

        const switchToTab = (year: number) => {
            currentTab.value = year;
        }

        api.getCustomLabels().then((res) => {
            printButtonLabel.value = res.data.resultsPagePrintButtonLabel || printButtonLabel.value;
        });

        if (store.state.competences) {
            const newRatings: number[][] = [[], [], [], []];
            for (const competence of store.state.competences) {
                newRatings[1].push(competence.getRating(store.state.answers, 1));
                newRatings[2].push(competence.getRating(store.state.answers, 2));
                newRatings[3].push(competence.getRating(store.state.answers, 3));
            }

            ratings.value = newRatings;
            switchToTab(1);
        }

        return {modalCompetence, ratings, currentTab, printButtonLabel, switchToTab};
    },
    watch: {
        $route() {
            this.toggleModal();
        },
    },
    methods: {
        toggleModal(competence?: CompetenceModel) {
            this.modalCompetence = competence;
            document.body.classList.toggle('modal-open', !!competence);
        }
    },
})
