<template>
    <section v-if="$store.state.competences" class="result-detail-page">
        <div class="block-wrapper block-wrapper-logo d-none print-show mb-4">
            <div class="svg-container">
                <site-logo/>
            </div>
        </div>

        <div class="result-competence-wrapper d-print-none">
            <div class="return-button pr-0">
                <div class="d-flex justify-content-between">
                    <div class="result-competence-wrapper__return-button">
                        <RouterLink :to="{name: 'ResultOverview'}" class="btn btn-link btn-link--left">
                            <span class="chevron-circle chevron-circle--left"><Icon icon="chevronCircleLeft"/></span>
                            Terug naar overzicht
                        </RouterLink>
                    </div>

                    <CompetenceResultNav class="d-lg-none d-print-none"/>
                </div>
            </div>
        </div>

        <div class="result-detail-print-button block-wrapper my-4 mt-lg-0 mb-lg-0 px-3 py-0 p-lg-0 bg-transparent">
            <div class="d-flex justify-content-end">
                <button class="btn btn-secondary d-print-none" onclick="window.print()">
                    {{ printButtonLabel }}
                </button>
            </div>
        </div>

        <div v-if="competence && content">
            <div class="block-wrapper">
                <div class="content-wrapper-sm">
                    <div class="title-width-dot d-flex justify-content-start align-items-center mb-3">
                        <span class="dot mr-3 mb-2" :style="{ backgroundColor: competence.menuColor }"/>
                        <div class="title mb-2">
                            <h2>{{ competence.name }}</h2>
                        </div>
                    </div>
                </div>

                <CompetenceDescriptionStand :competence="competence" class=""/>

                <div v-if="competence.getResultText($store.state.answers, 1)" class="block-wrapper m-0 print-pb-4">
                    <div class="content-wrapper-sm">{{ competence.getResultText($store.state.answers, 1) }}</div>
                </div>

                <div class="content-wrapper-sm d-none d-lg-block d-print-none">
                    <div class="row pt-lg-3">
                        <template v-for="(contentBlock, i) of content" :key="i">
                            <CompetenceInfoBlock v-if="contentBlock.data.length" :title="contentBlock.title" @info-btn="scrollTo(i)">
                                {{ contentBlock.description }}
                            </CompetenceInfoBlock>
                        </template>
                    </div>
                </div>

                <!-- TODO: tijdelijk fix voor lange block -->
                <div class="d-block d-lg-none mt-n4 w-100 h-25"/>
            </div>

            <div class="content-tab d-print-none">
                <div class="d-block d-lg-none">
                    <ul class="tab-navigation nav nav-pills nav-fill">
                        <li v-for="(contentLink, i) of content" :key="i" class="nav-item">
                            <a v-if="contentLink.data.length" class="nav-link cursor-pointer" :class="{'selected-tab': selectedTab === i}" @click="selectTab(i)">
                                {{ contentLink.title }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <template v-if="content">
                <div v-for="(contentSection, i) of content" :key="i" :ref="setContentRef" class="block-wrapper__inner d-lg-block" :class="{'d-none': selectedTab !== i}">
                    <section v-if="contentSection.data.length" class="block-wrapper">
                        <div class="content-wrapper-sm">
                            <div class="competence-subject">
                                <div class="competence-subject__title d-flex flex-nowrap justify-content-between align-items-start mb-3">
                                    <h4 class="d-none d-lg-block">{{ contentSection.title }}</h4>
                                </div>

                                <!-- If the data is a string display it as HTML. If not, use it to populate KnowledgeBaseBlocks -->
                                <div v-if="typeof contentSection.data[0] === 'string'" class="mb-5 position-relative">
                                    <ResultBlockSlickSlider :content="contentSection.data"/>
                                </div>
                                <div v-else>
                                    <ResultBlockKnowledgeItems :content="contentSection.data"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </template>
        </div>
    </section>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import CompetenceDescriptionStand from '@/components/CompetenceDescriptionStand.vue';
import {competenceMixin} from '@/router/competenceMixin';
import Icon from '@/components/Icon.vue';
import CompetenceInfoBlock from '@/components/CompetenceInfoBlock.vue';
import CompetenceResultNav from '@/components/CompetenceResultNav.vue';
import api, {Competence, KnowledgebaseItem} from '@/data/api';
import ResultBlockKnowledgeItems from '@/components/resultBlocks/ResultBlockKnowledgeItems.vue';
import ResultBlockSlickSlider from '@/components/resultBlocks/ResultBlockSlickSlider.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

type DataBlock = { readonly data: string[]|KnowledgebaseItem[], readonly title: string, readonly description: string };

export default defineComponent({
    name: 'ResultCompetence',
    components: {ResultBlockKnowledgeItems, CompetenceResultNav, CompetenceInfoBlock, CompetenceDescriptionStand, SiteLogo, Icon, ResultBlockSlickSlider, ProgressSpinner},
    mixins: [competenceMixin],
    data() {
        return {
            selectedTab: 0,
            contentRefs: [] as HTMLElement[],
            recommendedProducts: [] as KnowledgebaseItem[]|null,
            recommendedPractises: [] as KnowledgebaseItem[]|null,
            content: null as DataBlock[]|null,
            printButtonLabel: 'Print'
        }
    },
    computed: {
        filePrefix(): string {
            let prefix = '';

            if (this.competence) {
                prefix += `${this.competence.name} `;
            }

            return prefix;
        }
    },
    mounted() {
        if (this.competence) {
            const relevance = this.calculateRelevance(this.competence);
            const values: (string|number)[][] = [];
            for (const key in relevance) {
                if (Object.prototype.hasOwnProperty.call(relevance, key)) {
                    values.push([key, relevance[key]]);
                }
            }

            api.getRecommendedations(this.competence.id, values).then((res) => {
                this.recommendedPractises = res.data.practises;
                this.recommendedProducts = res.data.products;

                api.getCustomLabels().then((res) => {
                    this.content = Object.freeze([
                        {data: this.recommendedProducts, title: 'Producten', description: res.data.recommendedProductsInfo},
                        {data: this.recommendedPractises, title: 'Praktijkvoorbeelden', description: res.data.recommendedPractisesInfo},
                        {data: this.competence?.reflectionContent.actions, title: 'Actiepunten', description: res.data.actionInfo}
                    ]) as DataBlock[];

                    this.printButtonLabel = res.data.printButtonLabel || this.printButtonLabel;
                });
            });
        }
    },
    methods: {
        calculateRelevance(competence: Competence) {
            const tagRelevance = {} as { [key: string]: number };

            if (this.$store.state.answers.length > 1) {
                for (const uuid in competence.statements) {
                    if (uuid in this.$store.state.answers[1]) {
                        const statement = competence.statements[uuid];

                        for (const tagId of statement.tags) {
                            const priority = statement.weight * this.$store.state.answers[1][uuid];
                            if (!tagRelevance[tagId]) {
                                tagRelevance[tagId] = 0;
                            }

                            tagRelevance[tagId] += priority;
                        }
                    }
                }
            }

            return tagRelevance;
        },
        setContentRef(element: HTMLElement) {
            this.contentRefs.push(element);
        },
        scrollTo(index: number) {
            this.contentRefs[index].scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        selectTab(tab: number) {
            this.selectedTab = tab;
            window.dispatchEvent(new Event('resize'));
        }
    },
})
</script>