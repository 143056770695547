<template>
    <section class="result-overview-page">
        <div class="return-button">
            <SurveyNavigationButtons :forwards="false" previous-label="Terug naar de stellingen"/>
        </div>

        <div v-if="$store.state.competences && ratings[currentTab]" class="result-block-wrapper">
            <!--			<div class="block-wrapper block-wrapper-no-border-bottom p-0 mb-0 d-print-none">-->
            <!--				<nav class="nav nav-pills nav-fill">-->
            <!--					<button :disabled="currentTab === 1" class="nav-link" @click="() => switchToTab(1)">-->
            <!--						<span class="d-none d-sm-inline-block">Resultaten&nbsp;-&nbsp;</span>-->
            <!--						<span class="d-inline-block">jaar 1</span>-->
            <!--					</button>-->

            <!--					<button :disabled="currentTab === 2" class="nav-link" @click="() => switchToTab(2)">-->
            <!--						<span class="d-none d-sm-inline-block">Resultaten&nbsp;-&nbsp;</span>-->
            <!--						<span class="d-inline-block">jaar 2</span>-->
            <!--					</button>-->

            <!--					<button :disabled="currentTab === 3" class="nav-link" @click="() => switchToTab(3)">-->
            <!--						<span class="d-none d-sm-inline-block">Resultaten&nbsp;-&nbsp;</span>-->
            <!--						<span class="d-inline-block">jaar 3</span>-->
            <!--					</button>-->
            <!--				</nav>-->
            <!--			</div>-->


            <div class="block-wrapper block-wrapper-no-border-top mt-0">
                <div class="content-wrapper-sm">
                    <div >
                        <h2 class="h1 mb-n7">Resultaten</h2>

                        <div class="d-flex justify-content-end">
                            <button class="btn btn-secondary d-print-none" onclick="window.print()">
                                <span class="icon-save"><Icon icon="save"/></span> {{ printButtonLabel }} 
                            </button>
                        </div>
                    </div>
					
					

                    <RadarChart :key="`rc${currentTab}`" :competences="$store.state.competences" :ratings="ratings[currentTab]"/>

                    <div v-for="(competence, key) in $store.state.competences" :key="key" class="result-block">
                        <div class="title-width-dot d-flex justify-content-start align-items-start">
                            <span class="dot mr-3 mb-2" :style="{ backgroundColor: competence.menuColor }"/>

                            <div class="title mb-2">
                                <h3 class="d-inline mb-0">{{ competence.name }}</h3>
                                <a v-if="competence.description" class="d-inline ml-3" @click="toggleModal(competence)">
                                    <span class="icon-question-cirlce"><Icon icon="questionCircle"/></span>
                                </a>
                            </div>
                        </div>

                        <div class="result-block__inner">
                            <div class="d-flex justify-content-start align-items-start py-3">
                                <div class="print-mb-4">
                                    <span class="d-none d-print-inline text-secondary mr-3">Je gemiddelde score:</span>
                                    <RatingSymbol :rating="Math.round(ratings[currentTab][key])"/>
                                </div>

                                <div class="result-block__content d-print-inline-block">
                                    <div class="content-wrapper-sm">
                                        {{ competence.getResultText($store.state.answers, currentTab) }}
                                    </div>

                                    <RouterLink :to="`/scan/resultaten/${competence.id}`" class="btn btn-link btn-link--left">
                                        <span class="chevron-circle chevron-circle--right"><Icon icon="chevronCircleLeft"/></span>
                                        Werken aan dit criterium
                                    </RouterLink>
                                </div>
                            </div>

                            <div class="d-none d-print-block">
                                <h6 class="mb-3 page-break-after-avoid">Jouw individuele antwoorden:</h6>
                                <div class="result-statement">
                                    <RatingQuestion v-for="(statement, qKey) in competence.statements" :key="qKey" :statement="statement"/>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button class="btn btn-secondary d-print-none" onclick="window.print()">
                            <span class="icon-save"><Icon icon="save"/></span> {{ printButtonLabel }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ProgressSpinner v-else/>
    </section>

    <BaseModal :title="modalCompetence?.name ?? 'Info'" :show="!!modalCompetence" @onClose="toggleModal()">
        <p v-html="modalCompetence?.description"/>
    </BaseModal>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import SurveyNavigationButtons from '@/components/SurveyNavigationButtons.vue';
import Icon from '@/components/Icon.vue';
import BaseModal from '@/components/BaseModal.vue';
import RatingSymbol from '@/components/RatingSymbol.vue';
import RadarChart from '@/components/chartsJs/RadarChart.vue';
import {useStore} from 'vuex';
import {vuexStoreKey} from '@/store';
import {CompetenceModel} from '@/data/CompetenceModel';
import api from '@/data/api';
import RatingQuestion from '@/components/form/RatingQuestion.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'ResultOverview',
    components: {RatingQuestion, RatingSymbol, BaseModal, Icon, SurveyNavigationButtons, RadarChart, ProgressSpinner},
    setup() {
        const store = useStore(vuexStoreKey);
        const ratings = ref<number[][]>([[], [], [], []]);
        const modalCompetence = ref<CompetenceModel>();
        const currentTab = ref(1);
        const printButtonLabel = ref('Print');

        const switchToTab = (year: number) => {
            currentTab.value = year;
        }

        api.getCustomLabels().then((res) => {
            printButtonLabel.value = res.data.resultsPagePrintButtonLabel || printButtonLabel.value;
        });

        if (store.state.competences) {
            const newRatings: number[][] = [[], [], [], []];
            for (const competence of store.state.competences) {
                newRatings[1].push(competence.getRating(store.state.answers, 1));
                newRatings[2].push(competence.getRating(store.state.answers, 2));
                newRatings[3].push(competence.getRating(store.state.answers, 3));
            }

            ratings.value = newRatings;
            switchToTab(1);
        }

        return {modalCompetence, ratings, currentTab, printButtonLabel, switchToTab};
    },
    watch: {
        $route() {
            this.toggleModal();
        },
    },
    methods: {
        toggleModal(competence?: CompetenceModel) {
            this.modalCompetence = competence;
            document.body.classList.toggle('modal-open', !!competence);
        }
    },
})
</script>