
import {defineComponent} from 'vue'
import api, {KnowledgebaseItem} from '@/data/api';
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import {Routes} from '@/router';
import Icon from '@/components/Icon.vue';
import {getQueryString} from '@/data/guardHelper';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
	name: 'KnowledgebaseDetail',
	components: {KnowledgebaseBlock, Icon, ProgressSpinner},
	data() {
		return {
			knowledgebaseItem: null as KnowledgebaseItem|null,
			relatedItems: null as KnowledgebaseItem[]|null,
			totalRelatedItems: -1
		}
	},
	computed: {
		Routes() {
			return Routes;
		},
		tagIds(): undefined|string[] {
			return this.knowledgebaseItem?.tags.map(tag => `#${tag.slug}`);
		}
	},
	mounted() {
		const slug = getQueryString(this.$route.params.slug);

		if (slug) {
			api.getKnowledgeBaseItem(slug).then((res) => {
				this.knowledgebaseItem = res.data;

				const tags = this.knowledgebaseItem.tags.map(t => t.slug).join(',');
				const exclude = this.knowledgebaseItem.id.toString();

				if (tags) {
					api.getKnowledgeBaseItems({tags, exclude}).then((res) => {
						this.totalRelatedItems = res.data.length;
						this.relatedItems = res.data.slice(0, 3);
					});
				}

			});
		}

	}
})
