
import {defineComponent} from 'vue';
import CompetenceDescriptionStand from '@/components/CompetenceDescriptionStand.vue';
import {competenceMixin} from '@/router/competenceMixin';
import Icon from '@/components/Icon.vue';
import CompetenceInfoBlock from '@/components/CompetenceInfoBlock.vue';
import CompetenceResultNav from '@/components/CompetenceResultNav.vue';
import api, {Competence, KnowledgebaseItem} from '@/data/api';
import ResultBlockKnowledgeItems from '@/components/resultBlocks/ResultBlockKnowledgeItems.vue';
import ResultBlockSlickSlider from '@/components/resultBlocks/ResultBlockSlickSlider.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

type DataBlock = { readonly data: string[]|KnowledgebaseItem[], readonly title: string, readonly description: string };

export default defineComponent({
    name: 'ResultCompetence',
    components: {ResultBlockKnowledgeItems, CompetenceResultNav, CompetenceInfoBlock, CompetenceDescriptionStand, SiteLogo, Icon, ResultBlockSlickSlider, ProgressSpinner},
    mixins: [competenceMixin],
    data() {
        return {
            selectedTab: 0,
            contentRefs: [] as HTMLElement[],
            recommendedProducts: [] as KnowledgebaseItem[]|null,
            recommendedPractises: [] as KnowledgebaseItem[]|null,
            content: null as DataBlock[]|null,
            printButtonLabel: 'Print'
        }
    },
    computed: {
        filePrefix(): string {
            let prefix = '';

            if (this.competence) {
                prefix += `${this.competence.name} `;
            }

            return prefix;
        }
    },
    mounted() {
        if (this.competence) {
            const relevance = this.calculateRelevance(this.competence);
            const values: (string|number)[][] = [];
            for (const key in relevance) {
                if (Object.prototype.hasOwnProperty.call(relevance, key)) {
                    values.push([key, relevance[key]]);
                }
            }

            api.getRecommendedations(this.competence.id, values).then((res) => {
                this.recommendedPractises = res.data.practises;
                this.recommendedProducts = res.data.products;

                api.getCustomLabels().then((res) => {
                    this.content = Object.freeze([
                        {data: this.recommendedProducts, title: 'Producten', description: res.data.recommendedProductsInfo},
                        {data: this.recommendedPractises, title: 'Praktijkvoorbeelden', description: res.data.recommendedPractisesInfo},
                        {data: this.competence?.reflectionContent.actions, title: 'Actiepunten', description: res.data.actionInfo}
                    ]) as DataBlock[];

                    this.printButtonLabel = res.data.printButtonLabel || this.printButtonLabel;
                });
            });
        }
    },
    methods: {
        calculateRelevance(competence: Competence) {
            const tagRelevance = {} as { [key: string]: number };

            if (this.$store.state.answers.length > 1) {
                for (const uuid in competence.statements) {
                    if (uuid in this.$store.state.answers[1]) {
                        const statement = competence.statements[uuid];

                        for (const tagId of statement.tags) {
                            const priority = statement.weight * this.$store.state.answers[1][uuid];
                            if (!tagRelevance[tagId]) {
                                tagRelevance[tagId] = 0;
                            }

                            tagRelevance[tagId] += priority;
                        }
                    }
                }
            }

            return tagRelevance;
        },
        setContentRef(element: HTMLElement) {
            this.contentRefs.push(element);
        },
        scrollTo(index: number) {
            this.contentRefs[index].scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        selectTab(tab: number) {
            this.selectedTab = tab;
            window.dispatchEvent(new Event('resize'));
        }
    },
})
