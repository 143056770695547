
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'Paginator',
	props: {
		page: {type: Number, required: true},
		resultPerPage: {type: Number, required: true},
		totalResults: {type: Number, required: true}
	},
	emits: ['update:modelValue'],
	computed: {
		totalPages(): number {
			const totalPages = Math.max(Math.ceil(this.totalResults / this.resultPerPage), 1);

			if (this.page > totalPages) {
				this.setPage(totalPages);
			}

			return totalPages;
		}
	},
	methods: {
		setPage(page: number) {
			window.scrollTo({top: 0, behavior: 'smooth'});
			this.$emit('update:modelValue', page);
		}
	}
})
