<template>
    <div class="block-wrapper block-wrapper-logo d-none print-show mb-4">
        <div class="svg-container">
            <SiteLogo/>
        </div>
    </div>

    <div v-if="slug.includes('criteria-')" class="block-wrapper my-4 mt-lg-0 mb-lg-0 px-3 py-0 p-lg-0 bg-transparent d-print-none">
        <div class="d-flex justify-content-end">
            <button v-if="printButtonLabel" class="btn btn-secondary" onclick="window.print()">
                {{ printButtonLabel }}
            </button>
        </div>
    </div>

    <div class="main__content__inner">
        <section v-if="content" class="block-wrapper page-content" v-html="content"/>
        <ProgressSpinner v-else/>
    </div>

    <div v-if="products.length || bestPractises.length || explanations.length" class="main__content__inner d-print-none">
        <section class="block-wrapper page-content">
            <div class="d-flex justify-content-between align-items-start">
                <h3 class="mb-4">Gerelateerde producten</h3>

                <RouterLink :to="{name: Routes.KnowledgeBaseOverview}" class="btn btn-link btn-link--left mt-n1 mb-4">
                    <span class="chevron-circle chevron-circle--right"><Icon icon="chevronCircleLeft"/></span> Naar Kennisbank
                </RouterLink>
            </div>

            <ResultBlockKnowledgeItems v-if="products.length" :content="products"/>

            <ResultBlockKnowledgeItems v-if="bestPractises.length" :content="bestPractises"/>

            <ResultBlockKnowledgeItems v-if="explanations.length" :content="explanations"/>
        </section>
    </div>
</template>

<script lang="ts">
import api, {KnowledgebaseItem} from '@/data/api';
import {defineComponent, ref} from 'vue';
import store from '@/store';
import {getLatestStepPage} from '@/data/guardHelper';
import {useRoute} from 'vue-router';
import ResultBlockKnowledgeItems from '@/components/resultBlocks/ResultBlockKnowledgeItems.vue';
import Icon from '@/components/Icon.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import {Routes} from '@/router';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'ContentPage',
    components: {ProgressSpinner, ResultBlockKnowledgeItems, SiteLogo, Icon},
    setup() {
        const content = ref('');
        const products = ref<KnowledgebaseItem[]>([]);
        const bestPractises = ref<KnowledgebaseItem[]>([]);
        const explanations = ref<KnowledgebaseItem[]>([]);
        const printButtonLabel = ref('');

        let slug = useRoute().params.slug;
        slug = (typeof slug === 'string') ? slug : 'home';

        api.getContentPage(slug).then((response) => {
            content.value = response.data.content;
            products.value = response.data.products ?? [];
            bestPractises.value = response.data.bestPractises ?? [];
            explanations.value = response.data.explanations ?? [];
            printButtonLabel.value = response.data.printButtonLabel || printButtonLabel.value;
        });

        return {content, products, bestPractises, explanations, printButtonLabel, slug, Routes};
    },
    updated() {
        const startButton = document.querySelector('[data-start-scan-button]');
        if (startButton) {
            startButton.addEventListener('click', () => {
                store.commit('startScan');
                const route = getLatestStepPage(this.$store.state.steps);
                this.$router.push(route);
            });
        }

        const accordeons = document.querySelectorAll('[data-toggle="collapse"]') as NodeListOf<HTMLElement>;
        for(const accordeon of accordeons) {
            const targetId = accordeon.dataset.target;
            if (!targetId) {
                throw 'Accordeon element is missing a target Id.';
            }

            const target = document.getElementById(targetId.substring(1));
            if (!target) {
                throw 'Accordeon has target Id, but target could not be found.';
            }

            accordeon.addEventListener('click', () => {
                if (accordeon.classList.contains('collapsed')) {
                    accordeon.classList.remove('collapsed');
                    accordeon.setAttribute('aria-expanded', 'false');
                    target.classList.remove('show');
                } else {
                    accordeon.classList.add('collapsed');
                    accordeon.setAttribute('aria-expanded', 'true');
                    target.classList.add('show');
                }
            });
        }
    }
})
</script>