import {Statement} from '@/data/api';
import {Rating} from '@/data/enums';

export class StatementModel implements Statement {
    public readonly uuid;
    public readonly question;
    public readonly weight;
    public readonly answerPerYear;
    public readonly tags;

    constructor(data: Statement) {
        this.uuid = data.uuid;
        this.question = data.question;
        this.answerPerYear = data.answerPerYear;
        this.weight = data.weight;
        this.tags = data.tags;
    }

    public getAnswer(answers: {[key: string]: Rating}[], forYear: number)
    {
        const year = (this.answerPerYear) ? forYear : 0;

        if (!answers[year] || !(this.uuid in answers[year])) {
            return null;
        }

        return answers[year][this.uuid];
    }

    /** Check if a question is answered. If the question is a multi-year question, then every year must be answered */
    public isAnswered(answers: {[key: string]: Rating}[])
    {
        for (const year of this.getYears()) {
            const answer = this.getAnswer(answers, year);
            if (answer === null || !Object.values(Rating).includes(answer)) {
                return false;
            }
        }

        return true;
    }

    public getYears()
    {
        return (this.answerPerYear) ? [1, 2, 3] : [0];
    }
}