
import {defineComponent} from 'vue'
import Icon from '@/components/Icon.vue';

export default defineComponent({
	name: 'DropdownBase',
	components: {Icon},
	props: {
		buttonText: {type: String, required: true},
		disabled: {type: Boolean, default: false}
	},
	data() {
		return {
			isOpen: false
		}
	},
	methods: {
		open() {
			if (!this.disabled) {
				this.isOpen = true;
			}
		},
		close() {
			this.isOpen = false;
		}
	},
})
