
import {defineComponent} from 'vue'
import api, {KnowledgebaseItem, KnowledgeType, Tag} from '@/data/api';
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import Icon from '@/components/Icon.vue';
import {getQueryStrings} from '@/data/guardHelper';
import DropdownBase from '@/components/form/DropdownBase.vue';
import Paginator from '@/components/form/Paginator.vue';

const HashTagRegex = new RegExp(/#[a-zA-Z0-9_]+/g);

export default defineComponent({
	name: 'KnowledgebaseOverview',
	components: {Paginator, DropdownBase, KnowledgebaseBlock, Icon},
	props: {},
	data() {
		return {
			// Api Data
			knowledgebaseItems: null as KnowledgebaseItem[]|null,
			tags: null as Tag[]|null,
			knowledgeTypes: null as KnowledgeType[]|null,
			title: null as string|null,
			text: null as string|null,
			// Filtering
			searchInput: '',
			filterCompetence: [] as number[],
			filterType: [] as number[],
			page: 1,
			// Debounce search requests
			bouncer: null as number|null
		}
	},
	computed: {
		itemsPerPage(): number {
			return 9;
		},
		filteredItems(): KnowledgebaseItem[]|null {
			if (this.knowledgebaseItems) {
				return this.knowledgebaseItems.filter((item) => {
					if (this.filterType.length && item.knowledgeType && !this.filterType.includes(item.knowledgeType.id)) {
						return false;
					}

					if (this.filterCompetence.length && !this.overlapBetween(item.competences, this.filterCompetence)) {
						return false;
					}

					return true;
				});
			}

			return null;
		},
		itemsToDisplay(): KnowledgebaseItem[]|null {
			if (this.filteredItems) {
				const index = (this.page - 1) * this.itemsPerPage;
				return this.filteredItems.slice(index, index + this.itemsPerPage);
			}

			return null;
		}
	},
	mounted() {
		if (!this.$store.state.competences) {
			console.error('Competences are not set!');
			return;
		}

		api.getKnowledgeBaseOverviewText().then((res) => {
			this.title = res.data.title;
			this.text = res.data.text;
		});

		api.getKnowledgeTypes().then((res) => {
			this.knowledgeTypes = res.data;
		});

		// Initial Search field values
		const initialTagParams = getQueryStrings(this.$route.query.tags);
		if (initialTagParams) {
			this.searchInput += initialTagParams.join(' ');
		}

		const initialSearchParams = getQueryStrings(this.$route.query.search);
		if (initialSearchParams) {
			if (this.searchInput.length > 0) {
				this.searchInput += ' ';
			}
			this.searchInput += initialSearchParams.join(' ');
		}

		this.onSearch(0);
	},
	methods: {
		onSearch(timeout = 200) {
			const extractedTags = this.searchInput.match(HashTagRegex);
			if (extractedTags) {
				for (const key in extractedTags) {
					extractedTags[key] = extractedTags[key].replace(/[\s/]+/g, '-').replace(/[^\w-]+/g, '').toLowerCase();
				}
			}

			const tags = extractedTags ? extractedTags.join(',') : undefined;
			const search = this.searchInput.replace(HashTagRegex, '').replaceAll('#', '');

			if (this.bouncer) {
				clearTimeout(this.bouncer);
			}

			this.bouncer = setTimeout(() => {
				api.getKnowledgeBaseItems({tags, search}).then((res) => {
					this.knowledgebaseItems = res.data;
				});
			}, timeout);
		},
		overlapBetween(a: number[], b: number[]): boolean {
			for (const value of a) {
				if (b.includes(value)) {
					return true;
				}
			}

			return false;
		}
	},
})
