
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import SubmenuCompetence from '@/components/SubmenuCompetence.vue';
import SubmenuEntry from '@/components/SubmenuEntry.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import {ContentPages, Routes} from '@/router';
import {getLatestStepPage, isRouteIn} from '@/data/guardHelper';
import {LocationAsRelativeRaw} from 'vue-router';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
    name: 'HeaderMenu',
    components: {TransitionExpand, SiteLogo, SubmenuCompetence, SubmenuEntry, Icon},
    setup() {
        return {
            hamburgerOpen: false,
            criteriaItems: Object.freeze([
                {title: 'Werkdrukvermindering', color: '#4AB793'},
                {title: 'Enculturatie', color: '#EF466F'},
                {title: 'Professionele ontwikkeling', color: '#F3934F'},
                {title: 'Observatie en begeleiding in de klas', color: '#FED167'},
                {title: 'Intervisie en gerichte begeleiding', color: '#754092'},
                {title: 'Organisatie en borging', color: '#416EAB'}
            ]),
            kennisclipItems: Object.freeze([
                {title: 'Werkdrukvermindering', color: '#4AB793'},
                {title: 'Enculturatie', color: '#EF466F'},
                {title: 'Professionele ontwikkeling', color: '#F3934F'},
                {title: 'Observatie en begeleiding in de klas', color: '#FED167'},
                {title: 'Intervisie en gerichte begeleiding', color: '#754092'},
            ]),
        }
    },
    computed: {
        competences(): CompetenceModel[] | null {
            return this.$store.state.competences;
        },
        surveyIsComplete(): boolean {
            return !!this.$route.meta.isFinalPage;
        },
        surveyIsInProgress(): boolean {
            if (!this.surveyIsComplete && this.$route.name) {
                return isRouteIn({name: this.$route.name, params: this.$route.params}, this.$store.state.steps);
            }

            return false;
        },
        currentPage(): LocationAsRelativeRaw {
            return getLatestStepPage(this.$store.state.steps);
        },
        Routes() {
            return Routes;
        },
        ContentPages() {
            return ContentPages;
        }
    },
    watch: {
        $route() {
            this.hamburgerToggle(false);
        },
    },
    methods: {
        hamburgerToggle(force?: boolean) {
            this.hamburgerOpen = force ?? !this.hamburgerOpen;
            document.body.classList.toggle('drawer-open', this.hamburgerOpen);
        }
    }
})
