// noinspection JSUnusedGlobalSymbols

import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import {trackRouter} from 'vue-gtag-next';
import ContentPage from '@/views/ContentPage.vue';
import CompetenceQuestions from '@/views/CompetenceQuestions.vue';
import ResultOverview from '@/views/ResultOverview.vue';
import ResultCompetence from '@/views/ResultCompetence.vue';
import KnowledgebaseOverview from '@/views/KnowledgebaseOverview.vue';
import KnowledgebaseDetail from '@/views/KnowledgebaseDetail.vue';
import store from '@/store';
import {getLatestStepPage} from '@/data/guardHelper';

declare module 'vue-router' {
    interface RouteMeta {
        isFinalPage?: boolean
    }
}

export enum Routes {
    Home = 'Home',
    CompetenceQuestionList = 'CompetenceQuestions',
    ResultOverview = 'ResultOverview',
    ResultCompetenceAdvice = 'CompetenceResults',
    ContentPage = 'ContentPage',
    KnowledgeBaseOverview = 'KnowledgeBaseOverview',
    KnowledgebaseDetail = 'KnowledgebaseDetail',
    StartScan = 'StartScan'
}

export enum ContentPages {
    About = 'over-deze-tool',
    Colofon = 'colofon',
    Intro = 'introductie',
    CareerCompetences = 'criteria',
    KnowledgeClips = 'bsl-kennisclips',
}

type RouteRecordRawWithName = RouteRecordRaw & {
    name: Routes
}

const _routes: RouteRecordRawWithName[] = [
    {path: '/', name: Routes.Home, component: ContentPage},
    {path: '/scan/competentie/:id', name: Routes.CompetenceQuestionList, component: CompetenceQuestions},
    {path: '/scan/resultaten', name: Routes.ResultOverview, component: ResultOverview, meta: {isFinalPage: true}},
    {path: '/scan/resultaten/:id', name: Routes.ResultCompetenceAdvice, component: ResultCompetence, meta: {isFinalPage: true}},
    {path: '/kennisbank', name: Routes.KnowledgeBaseOverview, component: KnowledgebaseOverview},
    {path: '/kennisbank/:slug', name: Routes.KnowledgebaseDetail, component: KnowledgebaseDetail},
    {path: '/:slug', name: Routes.ContentPage, component: ContentPage},
    {path: '/start-scan', name: Routes.StartScan, redirect: () => {
        if (store.state.steps.length) {
            return getLatestStepPage(store.state.steps);
        }

        return {name: Routes.ContentPage, params: {slug: ContentPages.Intro}};
    }}
];

const router = createRouter({history: createWebHashHistory(), routes: _routes});

trackRouter(router);

export default router;