<template>
    <transition name="modal">
        <div v-if="show" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">{{ title }}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('onClose')">
                            <span class="icon-times-cirlce" aria-hidden="true"><icon icon="times"/></span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue';
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'BaseModal',
	components: {Icon},
	props: {
		title: {type: String, required: true},
		show: {type: Boolean, required: true}
	},
	emits: ['onClose']
})
</script>