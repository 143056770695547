import axios, {AxiosResponse} from 'axios';
import {CompetenceModel} from '@/data/CompetenceModel';
import {Rating} from '@/data/enums';

export type Answer = {[key: string]: Rating};

export interface Statement {
    readonly uuid: string,
    readonly question: string,
    readonly weight: number,
    readonly tags: number[],
    readonly answerPerYear: boolean
}

export interface ReflectionContent {
    readonly actions: string[]|null,
    readonly bestPractises: object[],
    readonly products: object[]
}

export interface CompetenceFiles {
    readonly exercises: string|null,
    readonly workQuestions: string|null
}

export interface Competence {
    readonly id: number,
    readonly name: string,
    readonly resultText: {[key: number]: string},
    readonly statements: {[key: string]: Statement},
    readonly menuColor: string,
    readonly description: string,
    readonly reflectionContent: ReflectionContent,
    readonly files: CompetenceFiles
}

export interface ContentPage {
    readonly content: string,
    readonly printButtonLabel: string,
    readonly bestPractises?: KnowledgebaseItem[],
    readonly products?: KnowledgebaseItem[]
    readonly explanations?: KnowledgebaseItem[]
}

export interface KnowledgebaseItem {
    readonly id: number,
    readonly slug: string,
    readonly content: string,
    readonly knowledgeType: KnowledgeType|null,
    readonly downloads: Download[],
    readonly image: string|null,
    readonly competences: number[],
    readonly tags: Tag[],
    readonly title: string
    readonly relevancyScore?: number;
}

export interface Tag {
    readonly id: number,
    readonly name: string,
    readonly slug: string
}

export interface KnowledgeType {
    readonly id: number,
    readonly name: string
}

export interface Download {
    readonly label: string,
    readonly linkType: 'url'|'file'
    readonly url: string
}

export interface CompetenceResultIntroText {
    readonly recommendedProductsInfo: string;
    readonly recommendedPractisesInfo: string;
    readonly printButtonLabel: string;
    readonly resultsPagePrintButtonLabel: string;
    readonly actionInfo: string;
}

// Methods
type AxiosPromise<T> = Promise<AxiosResponse<T>>;
export default {
    fetch(endpoint: string, params: {[key: string]: string|undefined} = {}) {
        const origin = (window.location.origin.includes('localhost')) ? `http://${window.location.host}` : window.location.origin;
        const url = new URL(`${origin}/wp/api/${endpoint}/`);

        for(const key in params) {
            const param = params[key];
            if (param !== undefined) {
                url.searchParams.append(key, param);
            }
        }

        return axios.get(url.toString());
    },

    getCompetences(): Promise<CompetenceModel[]> {
        return this.fetch('competences').then((response) => {
            const models = [];

            for(const chapterData of response.data) {
                models.push(new CompetenceModel(chapterData));
            }

            return models;
        });
    },

    getContentPage(slug: string): AxiosPromise<ContentPage> {
        return this.fetch('content-page', {slug});
    },

    getKnowledgeBaseItems(params?: {tags?: string, search?: string, exclude?: string}): AxiosPromise<KnowledgebaseItem[]> {
        return this.fetch('knowledge-base', params);
    },

    getKnowledgeBaseItem(slug: string): AxiosPromise<KnowledgebaseItem> {
        return this.fetch('knowledge-base-detail', {slug})
    },

    getTags(): AxiosPromise<Tag[]> {
        return this.fetch('tags');
    },

    getKnowledgeTypes(): AxiosPromise<KnowledgeType[]> {
        return this.fetch('knowledge-types');
    },

    getRecommendedations(competenceId: number, tags: (string | number)[][]): AxiosPromise<{products: KnowledgebaseItem[], practises: KnowledgebaseItem[]}> {
        return this.fetch('recommendations', {competenceId: competenceId.toString(), tags: tags.join(',')});
    },

    getKnowledgeBaseOverviewText(): AxiosPromise<{title: string, text: string}> {
        return this.fetch('knowledge-base-overview-text');
    },

    getCustomLabels(): AxiosPromise<CompetenceResultIntroText> {
        return this.fetch('tool-custom-labels');
    }
}
