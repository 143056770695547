<template>
    <div v-if="competence.description" class="more-info-stand rounded">
        <div class="cursor-pointer d-flex flex-nowrap justify-content-between align-items-start" @click="infoCollapse">
            <div class="more-info-stand__button-text pr-4">
                Meer over {{ competence.name }}
                <span><icon icon="questionCircle" /></span>
            </div>
            <button class="more-info-stand__button" :class="[collapseOpen ? '' : 'show']">
                <span><icon icon="chevronDown" /></span>
            </button>
        </div>

        <div class="collapse" :class="[collapseOpen ? '' : 'show']">
            <div class="py-2" v-html="competence.description" />
        </div>
    </div>
</template>
<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Icon from '@/components/Icon.vue';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
    name: 'CompetenceDescriptionStand',
    components: {Icon},
    props: {
        competence: {type: Object as PropType<CompetenceModel>, required: true}
    },
    data() {
        return {
            collapseOpen: true,
        }
    },
    methods: {
        infoCollapse() {
            this.collapseOpen = !this.collapseOpen;
        }
    }
})
</script>