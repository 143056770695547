import {Rating} from '@/data/enums';
import {MutationTree} from 'vuex';
import {State} from '@/store/state';
import {ContentPages, Routes} from '@/router';
import {CompetenceModel} from '@/data/CompetenceModel';

export type Mutations<S = State> = {
    'initialiseStore'(state: S): void
    'setAnswer'(state: S, payload: {uuid: string, rating: Rating|null, year: number}): void
    'setCompetences'(state: S, payload: CompetenceModel[]) : void
    'setYearsOfExperience'(state: S, payload: number) : void
    'startScan'(state: S) : void
}

export const mutations: MutationTree<State> & Mutations = {
    'initialiseStore'(state) {
        const store = localStorage.getItem('store');
        if(store) {
            this.replaceState(Object.assign(state, JSON.parse(store)));
        }

        _onMutation(state);
    },
    'setAnswer'(state, answer) {
        if (answer.rating === null) {
            delete state.answers[answer.year][answer.uuid];
        } else {
            if (!state.answers[answer.year]) {
                state.answers[answer.year] = {};
            }

            state.answers[answer.year][answer.uuid] = answer.rating;
        }

        _onMutation(state);
    },
    'setCompetences'(state, competences) {
        state.competences = competences;

        _onMutation(state);
    },
    'setYearsOfExperience'(state, amount) {
        state.yearsOfExperience = amount;

        _onMutation(state);
    },
    'startScan'(state) {
        state.startedScan = true;

        _onMutation(state);
    }
}

// A private helper function that is called after every mutation
const _onMutation = (state: State) => {
    state.steps = [];

    if (state.competences) {
        state.steps.push({name: Routes.ContentPage, params: {slug: ContentPages.Intro}});

        if (!state.startedScan) {
            return;
        }

        let completedCompetences = 0;

        for (const i in state.competences) {
            let answeredQuestions = 0;

            // Track how many questions have been answered
            for (const uuid in state.competences[i].statements) {
                const statement = state.competences[i].statements[uuid];

                if (statement.isAnswered(state.answers)) {
                    answeredQuestions += 1;
                } else if (answeredQuestions > 0) {
                    break;
                }
            }

            // Push this competence into the steps array, thus making it accessible
            state.steps.push({name: Routes.CompetenceQuestionList, params: {id: state.competences[i].id.toString()}});

            // If not all questions are answered, break out of the loop. As such the next competences won't be pushed into the steps array
            if (answeredQuestions === Object.values(state.competences[i].statements).length) {
                completedCompetences += 1;
            } else {
                break;
            }
        }

        // If every competence has been completed, push the result pages to the steps array
        if (completedCompetences === state.competences.length) {
            state.steps.push({name: Routes.ResultOverview});
            for (const competence of state.competences) {
                state.steps.push({name: Routes.ResultCompetenceAdvice, params: {id: competence.id.toString()}});
            }
        }
    }
};
