<!--suppress HtmlUnknownTag -->
<template>
    <div class="header-wrapper">
        <header class="header">
            <div class="header__inner">
                <RouterLink :to="{name: Routes.Home}" aria-label="Homepage Button">
                    <div class="header__site-logo">
                        <div class="svg-container">
                            <SiteLogo/>
                        </div>
                    </div>
                </RouterLink>

                <div class="navigation-drawer" :class="{'navigation-drawer-open': hamburgerOpen}">
                    <div class="header__menu-navigation">
                        <ul class="list-unstyled menu-navigation-list mb-0">
                            <li class="menu-option">
                                <RouterLink :to="{name: Routes.Home}" class="menu-navigation-list__link">
                                    <span><Icon icon="home"/></span>
                                    <span class="list-title">Home</span>
                                </RouterLink>
                            </li>

                            <li v-if="$store.state.steps.length">
                                <RouterLink :to="currentPage" class="menu-navigation-list__link">
                                    <span><Icon icon="videoPlayer"/></span>
                                    <span class="list-title">Doe de scan</span>
                                </RouterLink>

                                <TransitionExpand>
                                    <div class="list-unstyled menu-navigation-sublist">
                                        <ul v-if="competences" class="list-unstyled menu-navigation-sublist">
                                            <li class="sublist menu-option">
                                                <SubmenuEntry :path="{name: Routes.ContentPage, params: {slug: ContentPages.Intro}}">
                                                    <span><Icon icon="cPulse"/></span>
                                                    Introductie
                                                </SubmenuEntry>
                                            </li>

                                            <TransitionExpand>
                                                <ul v-if="surveyIsInProgress"
                                                    class="list-unstyled mb-0 overflow-hidden"
                                                >
                                                    <li v-for="(competence, key) in competences" :key="key" class="sublist">
                                                        <SubmenuCompetence :competence="competence"/>
                                                    </li>
                                                </ul>
                                            </TransitionExpand>

                                            <li class="sublist menu-option">
                                                <SubmenuEntry :path="{name: Routes.ResultOverview}">
                                                    <span><Icon icon="cPulse"/></span>
                                                    Resultaten
                                                </SubmenuEntry>
                                            </li>

                                            <TransitionExpand>
                                                <ul v-if="surveyIsComplete" class="list-unstyled mb-0 overflow-hidden">
                                                    <li v-for="(competence, key) in competences" :key="key"
                                                        class="sublist menu-option"
                                                    >
                                                        <SubmenuEntry :path="{name: Routes.ResultCompetenceAdvice, params: {id: competence.id.toString()}}" :color="competence.menuColor">
                                                            <span><Icon icon="hook"/></span>
                                                            <span class="line-clamp-2">{{ competence.name }}</span>
                                                        </SubmenuEntry>
                                                    </li>
                                                </ul>
                                            </TransitionExpand>
                                        </ul>
                                    </div>
                                </TransitionExpand>
                            </li>

                            <li class="menu-option">
                                <RouterLink :to="{name: Routes.ContentPage, params: {slug: ContentPages.CareerCompetences}}" class="menu-navigation-list__link">
                                    <span><Icon icon="checkList"/></span>
                                    <span class="list-title">Criteria</span>
                                </RouterLink>
                            </li>

                            <TransitionExpand>
                                <ul v-if="$route.params?.slug && $route.params.slug.includes('criteria')"
                                    class="list-unstyled mb-0 overflow-hidden"
                                >
                                    <li v-for="(criteriaItem, ckey) in criteriaItems" :key="ckey" class="sublist">
                                        <span class="sublist-accent" :style="{backgroundColor: criteriaItem.color}"/>
                                        <RouterLink :to="{name: Routes.ContentPage, params: {slug: `criteria-${criteriaItem.title.replace(/\s+/g, '-').toLowerCase()}`}}" class="menu-navigation-sublist__link done font-weight-normal">
                                            <span><Icon icon="hook"/></span>
                                            <span class="line-clamp-2">{{ criteriaItem.title }}</span>
                                        </RouterLink>
                                    </li>
                                </ul>
                            </TransitionExpand>

                            <li class="menu-option">
                                <RouterLink
                                    :to="{name: Routes.ContentPage, params: {slug: ContentPages.KnowledgeClips}}"
                                    class="menu-navigation-list__link"
                                >
                                    <span><Icon icon="filmstrip"/></span>
                                    <span class="list-title">Kennisclips</span>
                                </RouterLink>
                            </li>

                            <TransitionExpand>
                                <ul v-if="$route.params?.slug && $route.params.slug.includes('kennisclip')" class="list-unstyled mb-0 overflow-hidden">
                                    <li v-for="(kennisclipItem, ckey) in kennisclipItems" :key="ckey" class="sublist">
                                        <span class="sublist-accent" :style="{backgroundColor: kennisclipItem.color}"/>
                                        <RouterLink :to="{name: Routes.ContentPage, params: {slug: `kennisclip-${kennisclipItem.title.replace(/\s+/g, '-').toLowerCase()}`}}" class="menu-navigation-sublist__link done font-weight-normal">
                                            <span><Icon icon="hook"/></span>
                                            <span class="line-clamp-2">{{ kennisclipItem.title }}</span>
                                        </RouterLink>
                                    </li>
                                </ul>
                            </TransitionExpand>

                            <li class="menu-option">
                                <RouterLink :to="{name: Routes.KnowledgeBaseOverview}" class="menu-navigation-list__link">
                                    <span><Icon icon="knowledgeBase"/></span>
                                    <span class="list-title">Kennisbank</span>
                                </RouterLink>
                            </li>

                            <li class="menu-option">
                                <RouterLink :to="{name: Routes.ContentPage, params: {slug: ContentPages.About}}" class="menu-navigation-list__link">
                                    <span><Icon icon="info"/></span>
                                    <span class="list-title">Over deze tool</span>
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <button aria-label="Menu Button" class="hamburger" :class="{'hamburger-active': hamburgerOpen}"
                        @click="hamburgerToggle()"
                >
                    <span/>
                    <span/>
                    <span/>
                </button>

                <div class="header__copyright justify-content-center mt-auto w-100">
                    <small class="text-white mb-3">&copy;{{ new Date().getFullYear() }} alle rechten voorbehouden -
                        <RouterLink :to="{name: Routes.ContentPage, params: {slug: ContentPages.Colofon}}">Colofon
                        </RouterLink>
                    </small>
                </div>
            </div>
        </header>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import SubmenuCompetence from '@/components/SubmenuCompetence.vue';
import SubmenuEntry from '@/components/SubmenuEntry.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import {ContentPages, Routes} from '@/router';
import {getLatestStepPage, isRouteIn} from '@/data/guardHelper';
import {LocationAsRelativeRaw} from 'vue-router';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
    name: 'HeaderMenu',
    components: {TransitionExpand, SiteLogo, SubmenuCompetence, SubmenuEntry, Icon},
    setup() {
        return {
            hamburgerOpen: false,
            criteriaItems: Object.freeze([
                {title: 'Werkdrukvermindering', color: '#4AB793'},
                {title: 'Enculturatie', color: '#EF466F'},
                {title: 'Professionele ontwikkeling', color: '#F3934F'},
                {title: 'Observatie en begeleiding in de klas', color: '#FED167'},
                {title: 'Intervisie en gerichte begeleiding', color: '#754092'},
                {title: 'Organisatie en borging', color: '#416EAB'}
            ]),
            kennisclipItems: Object.freeze([
                {title: 'Werkdrukvermindering', color: '#4AB793'},
                {title: 'Enculturatie', color: '#EF466F'},
                {title: 'Professionele ontwikkeling', color: '#F3934F'},
                {title: 'Observatie en begeleiding in de klas', color: '#FED167'},
                {title: 'Intervisie en gerichte begeleiding', color: '#754092'},
            ]),
        }
    },
    computed: {
        competences(): CompetenceModel[] | null {
            return this.$store.state.competences;
        },
        surveyIsComplete(): boolean {
            return !!this.$route.meta.isFinalPage;
        },
        surveyIsInProgress(): boolean {
            if (!this.surveyIsComplete && this.$route.name) {
                return isRouteIn({name: this.$route.name, params: this.$route.params}, this.$store.state.steps);
            }

            return false;
        },
        currentPage(): LocationAsRelativeRaw {
            return getLatestStepPage(this.$store.state.steps);
        },
        Routes() {
            return Routes;
        },
        ContentPages() {
            return ContentPages;
        }
    },
    watch: {
        $route() {
            this.hamburgerToggle(false);
        },
    },
    methods: {
        hamburgerToggle(force?: boolean) {
            this.hamburgerOpen = force ?? !this.hamburgerOpen;
            document.body.classList.toggle('drawer-open', this.hamburgerOpen);
        }
    }
})
</script>
