import {Answer, Competence} from '@/data/api';
import {StatementModel} from '@/data/StatementModel';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {vuexStoreKey} from '@/store';

export class CompetenceModel implements Competence {
    public readonly id;
    public readonly name;
    public readonly resultText;
    public readonly statements;
    public readonly menuColor;
    public readonly description;
    public readonly files;
    public readonly reflectionContent;

    constructor(data: Competence) {
        this.id = data.id;
        this.name = data.name;
        this.resultText = data.resultText;
        this.menuColor = data.menuColor;
        this.description = data.description;
        this.files = data.files;
        this.reflectionContent = data.reflectionContent;

        const statements: {[key: string]: StatementModel} = {};
        for (const key in data.statements) {
            const rawStatement = data.statements[key];
            statements[key] = new StatementModel(rawStatement);
        }

        this.statements = statements;
    }

    public getAnswer(answers: Answer[], uid: string, forYear: number) {
        if (uid in this.statements) {
            return this.statements[uid].getAnswer(answers, forYear);
        }

        return null;
    }

    public getRating(answers: Answer[], forYear: number) {
        let totalWeight = 0;
        let totalScore = 0;

        for (const uid in this.statements) {
            const statement = this.statements[uid];
            const answer = statement.getAnswer(answers, forYear);

            if (answer !== null) {
                const statementWeight = statement.weight ?? 1;
                totalWeight += statementWeight;
                totalScore += answer * statementWeight;
            }
        }

        if (totalWeight === 0) {
            console.error(`The total weight of the ${this.name} competence is 0. This is not allowed.`);
            totalWeight = 1;
        }

        return totalScore / totalWeight;
    }

    public getEpsilonRating(answers: Answer[], forYear: number)
    {
        return Math.round((this.getRating(answers, forYear) + Number.EPSILON) * 100) / 100;
    }

    public getResultText(answers: Answer[], forYear: number)
    {
        const baseRating = this.getRating(answers, forYear);
        const flatRating = Math.round(baseRating);
        const invertedRating = Math.abs(flatRating - 2);

        return this.resultText[invertedRating];
    }

    public static get() {
        const store = useStore(vuexStoreKey);

        const rawId = useRoute().params['id'];
        const competenceId = (typeof rawId === 'string') ? parseInt(rawId) : 0;

        if (store.state.competences) {
            return store.state.competences.find((item) => {
                return item.id === competenceId;
            });
        }

        return undefined;
    }
}