import {ActionContext, ActionTree} from 'vuex';
import {State} from '@/store/state';
import {Mutations} from '@/store/mutations';
import api from '@/data/api';

export enum ActionResult {Error, Success}

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
    'fetchCompetences'({ commit }: AugmentedActionContext): Promise<ActionResult>
}

export const actions: ActionTree<State, State> & Actions = {
    async 'fetchCompetences'({ commit }) {
        return new Promise((resolve) => {
            api.getCompetences().then((competences) => {
                commit('setCompetences', competences);
                resolve(ActionResult.Success);
            }).catch(err => {
                console.error(err);
                resolve(ActionResult.Error);
            });
        });
    },
}