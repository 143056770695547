
import {defineComponent, PropType} from 'vue'
import Icon from '@/components/Icon.vue';
import {Rating} from '@/data/enums';

export default defineComponent({
	name: 'RatingSymbol',
	components: {Icon},
	props: {
		rating: {type: Number as PropType<Rating>, required: true}
	},
	computed: {
		Rating() {
			return Rating;
		}
	}
})
