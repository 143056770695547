<template>
    <div class="pagination">
        <button :disabled="page <= 1" @click="setPage(page - 1)">
            &#8592;
        </button>
        <button v-for="i in totalPages" :key="i" :disabled="page === i" :class="{'current-page': page === i}" @click="setPage(i)">
            {{ i }}
        </button>
        <button :disabled="page >= totalPages" @click="setPage(page + 1)">
            &#8594;
        </button>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'Paginator',
	props: {
		page: {type: Number, required: true},
		resultPerPage: {type: Number, required: true},
		totalResults: {type: Number, required: true}
	},
	emits: ['update:modelValue'],
	computed: {
		totalPages(): number {
			const totalPages = Math.max(Math.ceil(this.totalResults / this.resultPerPage), 1);

			if (this.page > totalPages) {
				this.setPage(totalPages);
			}

			return totalPages;
		}
	},
	methods: {
		setPage(page: number) {
			window.scrollTo({top: 0, behavior: 'smooth'});
			this.$emit('update:modelValue', page);
		}
	}
})
</script>