<template>
    <div v-if="isDisabled">
        <span v-if="color" class="sublist-accent opaque" :style="{ backgroundColor: color }"/>

        <span class="menu-navigation-sublist__link disabled">
            <span v-if="icon"><icon :icon="icon"/></span>
            <slot/>
        </span>
    </div>
    <div v-else>
        <span v-if="color" class="sublist-accent" :style="{ backgroundColor: color }"/>

        <router-link :to="path" class="menu-navigation-sublist__link done">
            <span v-if="icon"><icon :icon="icon"/></span>
            <slot/>
        </router-link>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Icon from '@/components/Icon.vue';
import {LocationAsRelativeRaw} from 'vue-router';
import {isRouteIn} from '@/data/guardHelper';

export default defineComponent({
    name: 'SubmenuEntry',
    components: {Icon},
    props: {
        path: {type: Object as () => LocationAsRelativeRaw, required: true},
        icon: {type: String, default: null},
        color: {type: String, default: null}
    },
    computed: {
        isDisabled(): boolean {
            return !isRouteIn(this.path, this.$store.state.steps);
        }
    },
})
</script>