<template>
    <div class="rating-question">
        <div class="rating-question__question ">
            <div class="rating-question__content">
                {{ statement.question }}
            </div>
        </div>

        <div class="rating-question__answer ">
            <template v-for="(year, key) in years" :key="key">
                <div v-if="years.length > 1" class="mb-0"><b>Jaar {{ year }}:</b></div>

                <div class="rating-question__buttons d-flex justify-content-between justify-content-sm-end align-items-center flex-nowrap">
                    <button class="btn btn-rating btn-outline-secondary" aria-label="positive" :class="{'selected': statement.getAnswer($store.state.answers, year) === Rating.Positive }" @click="onRate(Rating.Positive, year)">
                        <span class="plus"><Icon icon="plus" /></span>
                    </button>

                    <button class="btn btn-rating btn-outline-secondary" aria-label="neutral" :class="{'selected': statement.getAnswer($store.state.answers, year) === Rating.Neutral }" @click="onRate(Rating.Neutral, year)">
                        <span class="plusMinus"><Icon icon="plusMinus" /></span>
                    </button>

                    <button class="btn btn-rating btn-outline-secondary" aria-label="negative" :class="{'selected': statement.getAnswer($store.state.answers, year) === Rating.Negative }" @click="onRate(Rating.Negative, year)">
                        <span class="minus"><Icon icon="minus" /></span>
                    </button>
                </div>
            </template>
        </div>
    </div>
    <hr class="separator">
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Icon from '@/components/Icon.vue';
import {Rating} from '@/data/enums';
import {StatementModel} from '@/data/StatementModel';
import {vuexStoreKey} from '@/store';
import {useStore} from 'vuex';

export default defineComponent({
    name: 'RatingQuestion',
    components: {Icon},
    props: {
        statement: {type: Object as PropType<StatementModel>, required: true}
    },
    setup(props) {
        const store = useStore(vuexStoreKey);

        const years = props.statement.getYears();

        const onRate = (rating: Rating, forYear: number) => {
            store.commit('setAnswer', {
                uuid: props.statement.uuid,
                rating: rating,
                year: forYear,
            });
        }

        return {years, onRate, Rating};
    },
    computed: {
        selectedAnswer(): Rating | null {
            return null;
        }
    }
})
</script>