
// @ts-ignore
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import {defineComponent} from 'vue';
import {useGtm} from '@gtm-support/vue-gtm';

export default defineComponent({
    name: 'CookieBanner',
    components: {VueCookieAcceptDecline},
    setup() {
        return {
            cookieClickedAccept() {
                useGtm()?.enable();
            }
        };
    },
})
