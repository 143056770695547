<template>
    <div class="svg-loader d-flex justify-content-center align-items-center m-auto w-100">
        <svg class="svg-container" :height="`${size}px`" :width="`${size}px`" viewBox="0 0 100 100">
            <circle class="loader-svg bg" cx="50" cy="50" r="45"/>
            <circle class="loader-svg animate" cx="50" cy="50" r="45"/>
        </svg>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ProgressSpinner',
    props: {
        size: {type: Number, default: 64}
    }
})
</script>
