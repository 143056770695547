
import {defineComponent} from 'vue';
import {competenceMixin} from '@/router/competenceMixin';
import RatingSymbol from '@/components/RatingSymbol.vue';
import Icon from '@/components/Icon.vue';
import CompetenceNavItem from '@/components/CompetenceNavItem.vue';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
	name: 'CompetenceResultNav',
	components: {CompetenceNavItem, RatingSymbol, Icon},
	mixins: [competenceMixin],
	data() {
		return {
			isOpen: false
		}
	},
	computed: {
		competencesToDisplay(): CompetenceModel[] {
			const activeCompetence = this.competence ? [this.competence] : [];
			return this.isOpen ? this.$store.state.competences ?? [] : activeCompetence;
		}
	},
	watch: {
		$route() {
			this.isOpen = false;
		},
	},
	methods: {
		isSelected(competence: CompetenceModel) {
			return competence.id === this.competence?.id;
		},
	}
})
