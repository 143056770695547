<template>
    <span v-if="selected" @click="$emit('btnClick')">
        <slot/>
    </span>
    <router-link v-else :to="url">
        <slot/>
    </router-link>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'CompetenceNavItem',
	props: {
		selected: {type: Boolean, required: true},
		url: {type: String, required: true}
	},
	emits: ['btnClick'],
})
</script>