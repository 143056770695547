<template>
    <div class="col mb-3 d-none d-lg-block">
        <button type="button" class="button-block" @click="$emit('infoBtn')">
            <span class="button-block__title">{{ title }}</span>
            <span class="button-block__content">
                <slot />
            </span>
            <span class="button-block__button btn btn-link btn-link--left p-0">
                <span class="chevron-circle chevron-circle--right"><icon icon="chevronCircleLeft" /></span>
                Bekijk {{ title.toLowerCase() }}
            </span>
        </button>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    name: 'CompetenceInfoBlock',
    components: {Icon},
    props: {
        title: {type: String, required: true}
    },
    emits: ['infoBtn']
})
</script>