<template>
    <div class="return-button">
        <router-link :to="{name: Routes.KnowledgeBaseOverview}" class="btn btn-link btn-link--left">
            <span class="chevron-circle chevron-circle--left"><icon icon="chevronCircleLeft" /></span>
            Vorige
        </router-link>
    </div>

    <section v-if="knowledgebaseItem" class="knowledgebase-detail">
        <div class="block-wrapper block-wrapper--card mb-6">
            <div class="card card--image-card text-white">
                <div v-if="knowledgebaseItem.image" class="card-image card-image--mask">
                    <img class="card-img" :src="knowledgebaseItem.image" :alt="knowledgebaseItem.title">
                </div>
                <div v-else class="card-image card-image--no-image">
                    <div class="card-img-block"/>
                </div>

                <div class="card-img-overlay">
                    <div class="card-hover-type mb-3">{{ knowledgebaseItem.knowledgeType.name }}</div>
                    <h3 class="card-title mb-0">{{ knowledgebaseItem.title }}</h3>
                </div>
            </div>
        </div>

        <div v-if="knowledgebaseItem.content" class="block-wrapper my-6">
            <div class="block-wrapper__inner mb-n4">
                <div class="content-wrapper-sm">
                    <div class="knowledgebase-detail__content" v-html="knowledgebaseItem.content" />
                </div>
            </div>
        </div>

        <div v-if="knowledgebaseItem.downloads.length" class="block-wrapper block-wrapper--smaller my-6">
            <div class="block-wrapper__inner mb-n3">
                <div class="content-wrapper-sm">
                    <ul class="knowledgebase-detail__link-list">
                        <li v-for="(download, i) in knowledgebaseItem.downloads" :key="i">
                            <a :href="download.url" class="btn btn-link btn-link--left">
                                <span class="chevron-circle chevron-circle--right"><icon icon="chevronCircleLeft" /></span>
                                {{ download.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="relatedItems" class="block-wrapper mt-6">
            <div class="block-wrapper__inner mb-n4">
                <div class="content-wrapper-sm">
                    <div class="heading-plus-button d-flex justify-content-between align-items-start flex-column-reverse flex-sm-row mb-5">
                        <h3 class="mb-0">Gerelateerd</h3>

                        <router-link v-if="totalRelatedItems > 3" :to="{name: Routes.KnowledgeBaseOverview, query: {tags: tagIds}}" class="btn btn-link btn-link--left">
                            <span class="chevron-circle chevron-circle--left"><icon icon="chevronCircleLeft" /></span>
                            Naar overzicht
                        </router-link>
                    </div>

                    <div class="row">
                        <knowledgebase-block v-for="(item, i) in relatedItems" :key="i" :knowledgebase-item="item" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import api, {KnowledgebaseItem} from '@/data/api';
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import {Routes} from '@/router';
import Icon from '@/components/Icon.vue';
import {getQueryString} from '@/data/guardHelper';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
	name: 'KnowledgebaseDetail',
	components: {KnowledgebaseBlock, Icon, ProgressSpinner},
	data() {
		return {
			knowledgebaseItem: null as KnowledgebaseItem|null,
			relatedItems: null as KnowledgebaseItem[]|null,
			totalRelatedItems: -1
		}
	},
	computed: {
		Routes() {
			return Routes;
		},
		tagIds(): undefined|string[] {
			return this.knowledgebaseItem?.tags.map(tag => `#${tag.slug}`);
		}
	},
	mounted() {
		const slug = getQueryString(this.$route.params.slug);

		if (slug) {
			api.getKnowledgeBaseItem(slug).then((res) => {
				this.knowledgebaseItem = res.data;

				const tags = this.knowledgebaseItem.tags.map(t => t.slug).join(',');
				const exclude = this.knowledgebaseItem.id.toString();

				if (tags) {
					api.getKnowledgeBaseItems({tags, exclude}).then((res) => {
						this.totalRelatedItems = res.data.length;
						this.relatedItems = res.data.slice(0, 3);
					});
				}

			});
		}

	}
})
</script>