
import {defineComponent, PropType} from 'vue'
import SubmenuEntry from '@/components/SubmenuEntry.vue';
import {Routes} from '@/router';
import {isSameRoute, routeIndexOf} from '@/data/guardHelper';
import {navigationMixin} from '@/router/navigationMixin';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
    name: 'SubmenuCompetence',
    components: {SubmenuEntry},
    mixins: [navigationMixin],
    props: {
        competence: {type: Object as PropType<CompetenceModel>, required: true},
    },
    computed: {
        path() {
            return {name: Routes.CompetenceQuestionList, params: {id: this.competence.id.toString()}};
        },
        icon() {
            if (this.$route.name) {
                const currentRoute = {name: this.$route.name, params: this.$route.params};
                const competenceRoute = {name: Routes.CompetenceQuestionList, params: {id: this.competence.id.toString()}};

                if (isSameRoute(currentRoute, competenceRoute)) {
                    return 'pencil';
                }

                const routeIndex = routeIndexOf(competenceRoute, this.$store.state.steps);
                if (routeIndex >= 0 && routeIndex < this.$store.state.steps.length - 1) {
                    return 'check';
                }
            }

            return null;
        },
    },
})
