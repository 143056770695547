
import {defineComponent, PropType} from 'vue';
import {KnowledgebaseItem} from '@/data/api';
import {Routes} from '@/router';
import Icon from '@/components/Icon.vue';

export default defineComponent({
	name: 'KnowledgebaseBlock',
	components: {Icon},
	props: {
		knowledgebaseItem: {type: Object as PropType<KnowledgebaseItem>, required: true}
	},
	computed: {
		Routes() {
			return Routes;
		}
	},
	methods: {
		getColor() {
			if (this.$store.state.competences && this.knowledgebaseItem.competences.length) {
				for (const competence of this.$store.state.competences) {
					if (this.knowledgebaseItem.competences[0] === competence.id) {
						return competence.menuColor;
					}
				}
			}

			return 'white';
		}
	}
})
