<template>
    <div v-if="$store.state.steps.length">
        <router-link v-if="previousPage && backwards" :to="previousPage" class="btn btn-link btn-link--left">
            <span class="chevron-circle chevron-circle--left"><icon icon="chevronCircleLeft" /></span>
            {{ previousLabel }}
        </router-link>

        <router-link v-if="nextPage && forwards" :to="nextPage" class="btn btn-secondary">
            {{ nextLabel }}
        </router-link>
        <span v-else-if="forwards" class="btn btn-primary disabled">
            {{ nextLabel }}
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {navigationMixin} from '@/router/navigationMixin';
import Icon from '@/components/Icon.vue';
import {RouterLink} from 'vue-router';

export default defineComponent({
    name: 'SurveyNavigationButtons',
    components: {Icon, RouterLink},
    mixins: [navigationMixin],
    props: {
        backwards: {type: Boolean, default: true},
        forwards: {type: Boolean, default: true},
        previousLabel: {type: String, default: 'Vorige'},
        nextLabel: {type: String, default: 'Volgende'}
    }
})
</script>