
import jQuery from 'jquery';
import {defineComponent, PropType} from 'vue';
import {competenceMixin} from '@/router/competenceMixin';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

export default defineComponent({
	name: 'ResultBlockSlickSlider',
	components: {},
	mixins: [competenceMixin],
	props: {
		content: {type: Array as PropType<string[]>, required: true},
	},
	data() {
		return {
			unixTime: new Date().getTime()
		}
	},
	mounted() {
		jQuery('#slider').slick({
			dots: true,
			appendArrows: `#slider-arrows-${this.unixTime}`,
			infinite: false,
			prevArrow: '<span class="btn btn-link btn-link--left"><span class="chevron-circle chevron-circle--left"><svg viewBox="0 0 20 20" height="22px" width="22px" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 15c-.384 0-.768-.146-1.061-.439l-3.5-3.5a1.5 1.5 0 010-2.121l3.5-3.5a1.5 1.5 0 112.121 2.121L9.871 10l2.439 2.439A1.5 1.5 0 0111.25 15z"></path></svg></span>Vorige</span>',
			nextArrow: '<span class="btn btn-link btn-link--right">Volgende<span class="chevron-circle chevron-circle--right"><svg viewBox="0 0 20 20" height="22px" width="22px" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 15c-.384 0-.768-.146-1.061-.439l-3.5-3.5a1.5 1.5 0 010-2.121l3.5-3.5a1.5 1.5 0 112.121 2.121L9.871 10l2.439 2.439A1.5 1.5 0 0111.25 15z"></path></svg></span></span>',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						adaptiveHeight: true
					}
				}
			]
		});
	}
})
