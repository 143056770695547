<template>
    <div class="btn-rating outline-button-block outline-button-block--secondary">
        <span v-if="rating === Rating.Positive" class="plus">
            <icon icon="plus"/>
        </span>
        <span v-else-if="rating === Rating.Neutral" class="plusMinus">
            <icon icon="plusMinus"/>
        </span>
        <span v-else-if="rating === Rating.Negative" class="minus">
            <icon icon="minus"/>
        </span>
        <span v-else class="plusMinus">
            ???
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Icon from '@/components/Icon.vue';
import {Rating} from '@/data/enums';

export default defineComponent({
	name: 'RatingSymbol',
	components: {Icon},
	props: {
		rating: {type: Number as PropType<Rating>, required: true}
	},
	computed: {
		Rating() {
			return Rating;
		}
	}
})
</script>