<template>
    <div class="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-4 card-gutter-bottom" :title="knowledgebaseItem.tags.map(k => k.name).join(', ')">
        <div class="card card--knowledgebase-block h-100">
            <RouterLink :to="{name: Routes.KnowledgebaseDetail, params: {slug: knowledgebaseItem.slug}}">
                <div class="card-hover-type">
                    {{ knowledgebaseItem.knowledgeType.name }}
                </div>

                <div v-if="knowledgebaseItem.image" class="card-image card-image--mask">
                    <img class="card-img-top" :src="knowledgebaseItem.image" :alt="knowledgebaseItem.title">
                </div>
                <div v-else class="card-image card-image--no-image">
                    <div class="card-img-block" :style="{'background-color': getColor()}"/>
                </div>
            </RouterLink>

            <div class="card-body bg-white">
                <RouterLink tabindex="-1" :to="{name: Routes.KnowledgebaseDetail, params: {slug: knowledgebaseItem.slug}}">
                    <h5 class="card-title">{{ knowledgebaseItem.title }}</h5>
                </RouterLink>

                <div class="card-text" v-html="knowledgebaseItem.content" />

                <ul class="card-link-list mt-auto">
                    <li v-for="(download, index) in knowledgebaseItem.downloads" :key="index">
                        <a :href="download.url" target="_blank" class="btn btn-link btn-link--left">
                            <span class="chevron-circle chevron-circle--right"><Icon icon="chevronCircleLeft"/></span>
                            {{ download.label }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {KnowledgebaseItem} from '@/data/api';
import {Routes} from '@/router';
import Icon from '@/components/Icon.vue';

export default defineComponent({
	name: 'KnowledgebaseBlock',
	components: {Icon},
	props: {
		knowledgebaseItem: {type: Object as PropType<KnowledgebaseItem>, required: true}
	},
	computed: {
		Routes() {
			return Routes;
		}
	},
	methods: {
		getColor() {
			if (this.$store.state.competences && this.knowledgebaseItem.competences.length) {
				for (const competence of this.$store.state.competences) {
					if (this.knowledgebaseItem.competences[0] === competence.id) {
						return competence.menuColor;
					}
				}
			}

			return 'white';
		}
	}
})
</script>