
import {defineComponent, PropType} from 'vue';

interface IconData {path: string, x: number, y: number}

const icons: {[key: string]: IconData} = Object.freeze({
    home: {x: 21, y: 21, path: 'M4.375 21a2.625 2.625 0 01-2.62-2.47l-.005-.155v-7a.875.875 0 011.744-.102l.006.102v7c0 .449.338.819.773.87l.102.005H7v-6.125c0-.449.338-.819.773-.87l.102-.005h5.25c.449 0 .819.338.87.773l.005.102-.001 6.125h2.626a.875.875 0 00.87-.773l.005-.102v-7a.875.875 0 011.744-.102l.006.102v7a2.625 2.625 0 01-2.47 2.62l-.155.005H4.375zm7.875-7h-3.5v5.25h3.499L12.25 14zm-.431-7c.238 0 .431.193.431.431v2.638a.431.431 0 01-.431.431H9.18a.431.431 0 01-.431-.431V7.43c0-.238.193-.431.431-.431h2.638zM4.375 0c.449 0 .819.338.87.773l.005.102v2.736L9.985.167c.276-.2.64-.22.935-.06l.095.06 9.625 7a.875.875 0 01-.937 1.475l-.093-.06-9.11-6.626-9.11 6.627a.875.875 0 01-1.153-.109l-.07-.084a.875.875 0 01.109-1.153l.084-.07 3.252-2.364a.87.87 0 01-.106-.326L3.5 4.375v-3.5C3.5.392 3.892 0 4.375 0z'},
    info: {x: 22, y: 22, path: 'M11 .1C17.02.1 21.9 4.98 21.9 11c0 6.02-4.88 10.9-10.9 10.9C4.98 21.9.1 17.02.1 11 .1 4.98 4.98.1 11 .1zm0 1.8a9.1 9.1 0 100 18.2 9.1 9.1 0 000-18.2zm.9 7.29v7.255h-1.8V9.191h1.8zM11 5.546a.91.91 0 110 1.819.91.91 0 010-1.819z'},
    videoPlayer: {x: 24, y: 22, path: 'M21 0a3 3 0 013 3v16a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 2H3a1 1 0 00-1 1v16a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1zM9 5.131L17.803 11 9 16.869V5.13zm2 3.738v4.261L14.196 11 11 8.869z'},
    checkList: {x: 23, y: 22, path: 'M7.96 15.279v6.68H1.04v-6.68h6.92zm-1.92 1.918H2.959v2.843H6.04v-2.843zm16.92.463v1.918H9.54V17.66h13.42zM7.363 7.27L8.85 8.482l-4.762 5.83-3.444-3.28 1.323-1.39 1.945 1.853 3.452-4.227zm15.595 2.77v1.92H9.541v-1.92h13.418zm-15-10v6.681H1.041V.041h6.918zM6.04 1.96H2.959v2.843H6.04V1.959zm16.92.463V4.34H9.54V2.422h13.42z'},
    check: {x: 21, y: 16, path: 'M6.67 12.625L1.69 7.65 0 9.34 6.67 16 21 1.688 19.31 0z'},
    pencil: {x: 19, y: 19, path: 'M13.77.897a3.064 3.064 0 114.332 4.335L6.893 16.392l-.246.167-5.541 2.375c-.657.281-1.321-.383-1.04-1.04l2.375-5.54.167-.247zm-1.122 3.37l-8.813 8.85L2.3 16.698l3.586-1.537 8.848-8.809-2.085-2.085zm4.335-2.25a1.48 1.48 0 00-2.093-.001l-1.126 1.129 2.09 2.09 1.13-1.124a1.481 1.481 0 00.214-1.823l-.082-.12z'},
    questionCircle: {x: 15, y: 15, path: 'M7.5 0C3.364 0 0 3.364 0 7.5S3.364 15 7.5 15 15 11.636 15 7.5 11.636 0 7.5 0zm-.313 11.875a.938.938 0 11.001-1.876.938.938 0 010 1.876zm2.695-5.546c-.193.31-.56.657-1.101 1.043-.876.648-.852.792-.852 1.378H6.393c0-.458-.01-.81.238-1.238.158-.274.448-.566.869-.875.506-.361.997-.71.997-1.316 0-.568-.486-.77-1.054-.77-.58 0-1.24.189-1.982.568L4.829 3.85c1.348-.756 3.475-1.098 4.638-.15.854.695.86 1.918.415 2.63z'},
    chevronDown: {x: 25, y: 25, path: 'M21.61,7.35,12.5,14.64,3.38,7.35A2.08,2.08,0,1,0,.78,10.6L11.2,18.94a2.1,2.1,0,0,0,2.6,0L24.22,10.6a2.08,2.08,0,0,0-2.61-3.25Z'},
    chevronCircleLeft: {x: 20, y: 20, path: 'M11.25 15c-.384 0-.768-.146-1.061-.439l-3.5-3.5a1.5 1.5 0 010-2.121l3.5-3.5a1.5 1.5 0 112.121 2.121L9.871 10l2.439 2.439A1.5 1.5 0 0111.25 15z'},
    plusMinus: {x: 39, y: 23, path: 'M13.228 22.5v-9.272H22.5V9.207h-9.272V0h-4v9.207H0v4.02h9.229V22.5h3.999zm24.879-9.283v-4.01H26v4.02l12.107-.01z'},
    plus: {x: 23, y: 23, path: 'M13.45 22.913v-9.272h9.273V9.62h-9.272V.413h-4V9.62H.224v4.02h9.229v9.273z'},
    minus: {x: 13, y: 5, path: 'M12.56 4.102V.322H.453v3.78z'},
    save: {x: 448, y: 512, path: 'M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0'},
    plantGround: {x: 21, y: 20, path: 'M10.975.25a.75.75 0 01.743.648l.007.102-.001 6.75a4.849 4.849 0 013.797-1.816h1.429v3.403c0 2.615-2.162 4.729-4.821 4.729l-.405-.001.001 1.4c1.253.248 2.244 1.269 2.574 2.58l.045.204L20 18.25a.75.75 0 01.743.648l.007.102a.75.75 0 01-.648.743L20 19.75h-7.083V19c0-1.173-.82-2.092-1.784-2.092-.66 0-1.269.435-1.577 1.125l-.062.151-.395 1.087-.83-.804a.435.435 0 00-.302-.138c-.239 0-.467.229-.51.558L7.45 19v.75H1a.75.75 0 01-.102-1.493L1 18.25l5.074-.001.045-.12c.29-.715.928-1.236 1.701-1.295l.147-.005c.112 0 .223.01.331.03l.174.04.05-.073a3.288 3.288 0 011.702-1.279v-3.376h-.403c-2.587 0-4.704-2-4.816-4.518L5 7.443V4.038h1.429c1.54 0 2.913.71 3.797 1.815L10.225 1a.75.75 0 01.75-.75zm4.475 7.186l-.117.003c-1.75.096-3.133 1.506-3.133 3.224v1.9l.117-.002c1.688-.092 3.034-1.407 3.128-3.041l.005-.183V7.436zM6.499 5.541L6.5 7.442c0 1.78 1.483 3.23 3.321 3.23l-.072-.003.001-1.9c0-1.719-1.383-3.13-3.133-3.224L6.5 5.54z'},
    usersWw: {x: 21, y: 19, path: 'M7.486 1.001l.188.004.2.015c2.461.245 4.35 2.253 4.397 4.7v.164a6.057 6.057 0 001.23 4.053l.357.468-.428.407c-.478.455-1.115.759-1.869.946a7.868 7.868 0 01-1.558.216h-.017l.172 1.171 3.358.94A2.007 2.007 0 0115 16.006v2.994H0v-2.994c0-.893.606-1.678 1.484-1.923l3.358-.939.171-1.17-.269-.015a7.904 7.904 0 01-1.043-.142l-.262-.06c-.754-.187-1.391-.49-1.87-.946l-.425-.405.352-.467a6.247 6.247 0 001.231-4.272c0-1.27.53-2.485 1.466-3.365a4.827 4.827 0 013.089-1.297l.204-.005zm6.514-1l.191.004c2.424.171 4.296 2.263 4.26 4.794a6.177 6.177 0 001.022 3.8l.128.183.332.46-.4.4c-.445.448-1.041.746-1.745.93-.408.107-.821.17-1.219.199l-.252.014.16 1.15 3.137.923c.783.23 1.334.944 1.383 1.771l.003.12V19h-5.094v-1.31h3.82v-2.943a.656.656 0 00-.39-.603l-.072-.027-3.135-.922a1.295 1.295 0 01-.894-.974l-.018-.1-.377-2.711.87.065a6.153 6.153 0 001.765-.174c.224-.059.428-.131.608-.216l.136-.07-.024-.04a7.49 7.49 0 01-1.026-3.994l.01-.233c.025-1.804-1.326-3.313-3.057-3.436a3.097 3.097 0 00-1.744.454l-.141.092-.53.364L11 1.133l.53-.364A4.356 4.356 0 0114 .001zM7.46 2.335l-.17.006a3.446 3.446 0 00-2.152.923c-.669.629-1.047 1.497-1.05 2.352a7.5 7.5 0 01-1.09 4.52l-.023.037.148.072c.145.066.304.123.476.173l.176.048c.636.157 1.32.2 1.89.177l.93-.067-.403 2.758c-.076.521-.459.95-.975 1.094l-3.359.938a.669.669 0 00-.494.641v1.66h12.272v-1.66a.667.667 0 00-.417-.614l-.078-.027-3.357-.938a1.343 1.343 0 01-.976-1.094l-.403-2.758.846.062.303.01a6.808 6.808 0 001.671-.182c.3-.075.567-.171.798-.291l-.026-.04a7.327 7.327 0 01-1.099-4.065l.011-.237c.026-1.836-1.42-3.371-3.257-3.495l-.192-.003z'},
    cPulse: {x: 21, y: 21, path: 'M10.5.25c5.66 0 10.25 4.59 10.25 10.25S16.16 20.75 10.5 20.75.25 16.16.25 10.5 4.84.25 10.5.25zM8.637 7.863l-2.03 3.387H1.781a8.75 8.75 0 0017.436 0h-3.976l-3.15 5.25-3.455-8.637zM10.5 1.75a8.75 8.75 0 00-8.718 8h3.974L8.908 4.5l3.454 8.636 2.032-3.386h4.824a8.75 8.75 0 00-8.718-8z'},
    downloadArrow: {x: 13, y: 12, path: 'M4 3.5a.5.5 0 01.09.992L4 4.5H2a.5.5 0 00-.492.41L1.5 5v5a.5.5 0 00.41.492L2 10.5h9a.5.5 0 00.492-.41L11.5 10V5a.5.5 0 00-.41-.492L11 4.5H9a.5.5 0 01-.09-.992L9 3.5h2a1.5 1.5 0 011.493 1.356L12.5 5v5a1.5 1.5 0 01-1.356 1.493L11 11.5H2a1.5 1.5 0 01-1.493-1.356L.5 10V5a1.5 1.5 0 011.356-1.493L2 3.5h2zm2.5-3a.5.5 0 01.492.41L7 1v6.793l2.146-2.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-3 3-.013.011a.503.503 0 01-.039.033l.052-.044A.502.502 0 016.5 9.5h-.02a.503.503 0 01-.052-.005L6.5 9.5a.502.502 0 01-.284-.089l-.018-.013a.503.503 0 01-.04-.033l-.012-.011-3-3a.5.5 0 01.638-.765l.07.057L6 7.793V1a.5.5 0 01.5-.5z'},
    hook: {x: 21, y: 21, path: 'M10.4.5v10.1h10.1v1.8H8.6V.5z'},
    times: {x: 17, y: 16, path: 'M13.578.524a1.92 1.92 0 012.586 2.834L11.52 8l4.643 4.642a1.92 1.92 0 01-2.586 2.834l-.13-.118-4.642-4.643-4.642 4.643-.13.118a1.92 1.92 0 01-2.585-2.834L6.09 8 1.45 3.358A1.92 1.92 0 014.035.524l.129.118 4.642 4.643L13.45.642z'},
    search: {x: 17, y: 18, path: 'M6.78005865,0 C3.03170841,0 0,3.13646152 0,7.01432665 C0,10.8921922 3.03170841,14.0286533 6.78005865,14.0286533 C8.11831022,14.0286533 9.35685504,13.6224928 10.4068915,12.9326648 L15.3049853,18 L17,16.2464183 L12.1642229,11.256447 C13.0335413,10.0766476 13.5601173,8.61479216 13.5601173,7.01432665 C13.5601173,3.13646152 10.5284093,0 6.78005865,0 Z M6.88095238,1.63636364 C9.79492198,1.63636364 12.1428571,4.00941041 12.1428571,6.95454545 C12.1428571,9.8996805 9.79492198,12.2727273 6.88095238,12.2727273 C3.96698279,12.2727273 1.61904762,9.8996805 1.61904762,6.95454545 C1.61904762,4.00941041 3.96698279,1.63636364 6.88095238,1.63636364 Z'},
    angleDown: {x: 12, y: 8, path: 'M1.80248963 0L0 1.8481175 5.99999968 8 12 1.8481175 10.1975104 0 5.99999968 4.30376501z'},
    knowledgeBase: {x: 24, y: 22, path: 'M4.2,0 C3.21952464,0 2.4,0.834701022 2.4,1.83333333 L2.4,17.1111111 L0,17.1111111 L0,19.5555556 L2.4,19.5555556 L2.4,22 L4.8,22 L4.8,19.5555556 L7.8,19.5555556 L19.2,19.5555556 L19.2,22 L21.6,22 L21.6,19.5555556 L24,19.5555556 L24,17.1111111 L21.7734372,17.1111111 C21.7931916,16.7778576 21.7222344,16.488153 21.6609372,16.2469621 L18.9632808,5.14908851 L18.9609372,5.14192702 C18.8707512,4.78719218 18.842868,4.31098837 18.2414064,3.86002601 C17.9406756,3.63454496 17.557086,3.55203273 17.2875,3.55208333 C17.017914,3.55213393 16.8177288,3.59838368 16.6125,3.64995657 L14.4,4.19661464 L14.4,1.83333333 C14.4,0.834701022 13.5804756,0 12.6,0 L9,0 C8.78926476,0 8.58932592,0.0441243 8.4,0.114583333 C8.21067408,0.0441243 8.01073524,0 7.8,0 L4.2,0 Z M4.8,2.44444444 L7.2,2.44444444 L7.2,17.1111111 L4.8,17.1111111 L4.8,2.44444444 Z M9.6,2.44444444 L12,2.44444444 L12,17.1111111 L9.6,17.1111111 L9.6,2.44444444 Z M16.7320308,6.13736982 L19.2468744,16.4880638 L16.8,17.1111111 L16.7906256,17.1111111 L14.4093744,6.71028649 L16.7320308,6.13736982 Z'},
    filmstrip: {x: 22, y: 24, path: 'M21.3530429,0 L20.8297056,0 L20.8297056,2.30435713 L19.1684801,2.30435713 L19.1684801,0 L17.8294943,0 L17.8294943,5.15463718 L3.65067622,5.15463718 L3.65067622,0 L2.71059099,0 L2.71059099,2.30435713 L1.04991469,2.30435713 L1.04991469,0.000441998108 C0.469246353,0.00436473131 0,0.4787392 0,1.0633922 L0,22.9363868 C0,23.5234155 0.472980555,24 1.05727326,24 L3.65100571,24 L3.65100571,16.9949377 L17.8290001,16.9949377 L17.8290001,24 L21.3524389,24 C21.9363472,24 22,23.5238575 22,22.9363868 L22,1.0633922 C22.0004327,0.476142462 21.9366218,0 21.3530429,0 Z M2.71092048,23.3456218 L1.05024418,23.3456218 L1.05024418,20.7220868 L2.71097539,20.7220868 L2.71097539,23.3456218 L2.71092048,23.3456218 Z M2.71092048,19.2817807 L1.05024418,19.2817807 L1.05024418,16.6581904 L2.71097539,16.6581904 L2.71097539,19.2817807 L2.71092048,19.2817807 Z M2.71092048,14.9213036 L1.05024418,14.9213036 L1.05024418,12.2980448 L2.71097539,12.2980448 L2.71097539,14.9213036 L2.71092048,14.9213036 Z M2.71092048,10.8125445 L1.05024418,10.8125445 L1.05024418,8.18911994 L2.71097539,8.18911994 L2.71097539,10.8125445 L2.71092048,10.8125445 Z M2.71092048,6.45206738 L1.05024418,6.45206738 L1.05024418,3.82864286 L2.71097539,3.82864286 L2.71097539,6.45206738 L2.71092048,6.45206738 Z M17.8282313,15.3648487 L3.65067622,15.3648487 L3.65067622,6.7854997 L17.8282313,6.7854997 L17.8282313,15.3648487 Z M20.8283876,23.3456218 L19.1679859,23.3456218 L19.1679859,20.7220868 L20.8283876,20.7220868 L20.8283876,23.3456218 Z M20.8283876,19.2817807 L19.1679859,19.2817807 L19.1679859,16.6581904 L20.8283876,16.6581904 L20.8283876,19.2817807 Z M20.8283876,14.9213036 L19.1679859,14.9213036 L19.1679859,12.2980448 L20.8283876,12.2980448 L20.8283876,14.9213036 Z M20.8283876,10.8125445 L19.1679859,10.8125445 L19.1679859,8.18911994 L20.8283876,8.18911994 L20.8283876,10.8125445 Z M20.8283876,6.45206738 L19.1679859,6.45206738 L19.1679859,3.82864286 L20.8283876,3.82864286 L20.8283876,6.45206738 Z' }
});

export default defineComponent({
    name: 'Icon',
    props: {
        icon: {type: String as PropType<keyof typeof icons>, required: true}
    },
    computed: {
        path() {
            return icons[this.icon].path;
        },
        viewbox() {
            return `0 0 ${icons[this.icon].x} ${icons[this.icon].y}`;
        }
    },
})
