
import {defineComponent, PropType} from 'vue';
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';
import {CompetenceModel} from '@/data/CompetenceModel';


export default defineComponent({
	name: 'RadarChart',
	components: {Vue3ChartJs},
	props: {
		competences: {type: Array as PropType<CompetenceModel[]>, required: true},
		ratings: {type: Array as PropType<number[]>, required: true}
	},
	data() {
		return {
			type: 'radar',
			options: {
				plugins: {
					legend: {display: false},
				},
				scales: {
					r: {
						ticks: {
							beginAtZero: true,
							maxTicksLimit: 3,
							callback: (value: number) => {
								switch(value) {
									case 0:
										return '-';
									case 2:
										return '+';
									default:
										return '+-';
								}
							}
						},
						suggestedMin: 0,
						suggestedMax: 2
					}
				}
			},
			data: {
				labels: this.competences.map(c => c.name),
				datasets: [
					{
						label: 'Resultaat',
						data: this.ratings.map(r => Math.abs(r - 2)),
						borderColor: '#0E7DA2',
						backgroundColor: this.setOpacity('#0E7DA2', 0.7),
					}
				],
			}
		}
	},
	methods: {
		setOpacity(hex: string, alpha: number){
			return `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;
		},
	},
});
