<template>
    <div class="filter-select mr-3" tabindex="0" @mouseover="open" @mouseout="close">
        <div class="btn filter-select__dropdown-button" :disabled="disabled">
            {{ buttonText }}
            <span class="icon-chevronDown"><icon icon="angleDown"/></span>
        </div>

        <div v-if="!disabled" :class="{'hide-unless-tabbed': !isOpen}">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Icon from '@/components/Icon.vue';

export default defineComponent({
	name: 'DropdownBase',
	components: {Icon},
	props: {
		buttonText: {type: String, required: true},
		disabled: {type: Boolean, default: false}
	},
	data() {
		return {
			isOpen: false
		}
	},
	methods: {
		open() {
			if (!this.disabled) {
				this.isOpen = true;
			}
		},
		close() {
			this.isOpen = false;
		}
	},
})
</script>