
import {defineComponent} from 'vue'

export default defineComponent({
	name: 'TransitionExpand',
	methods: {
		enter(element: HTMLElement) {
			element.style.width = getComputedStyle(element).width;
			element.style.position = 'absolute';
			element.style.visibility = 'hidden';
			element.style.height = 'auto';

			const height = getComputedStyle(element).height;

			element.style.removeProperty('width')
			element.style.removeProperty('position')
			element.style.removeProperty('visibility')
			element.style.height = '0';

			// Force repaint to make sure the
			// animation is triggered correctly.
			getComputedStyle(element).height;

			// Trigger the animation.
			// We use `requestAnimationFrame` because we need
			// to make sure the browser has finished
			// painting after setting the `height`
			// to `0` in the line above.
			requestAnimationFrame(() => {
				element.style.height = height;
			});
		},
		afterEnter(element: HTMLElement) {
			element.style.height = 'auto';
		},
		leave(element: HTMLElement) {
			element.style.height = getComputedStyle(element).height;

			// Force repaint to make sure the
			// animation is triggered correctly.
			getComputedStyle(element).height;

			requestAnimationFrame(() => {
				element.style.height = '0';
			});
		},
	},
});
