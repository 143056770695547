<template>
    <div class="row">
        <KnowledgebaseBlock v-for="(item, j) in content" :key="j" :knowledgebase-item="item" />
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import {KnowledgebaseItem} from '@/data/api';

export default defineComponent({
	name: 'ResultBlockKnowledgeItems',
	components: {KnowledgebaseBlock},
	props: {
		content: {type: Array as PropType<KnowledgebaseItem[]>, required: true}
	}
})
</script>