
import {defineComponent, PropType} from 'vue'
import Icon from '@/components/Icon.vue';
import {Rating} from '@/data/enums';
import {StatementModel} from '@/data/StatementModel';
import {vuexStoreKey} from '@/store';
import {useStore} from 'vuex';

export default defineComponent({
    name: 'RatingQuestion',
    components: {Icon},
    props: {
        statement: {type: Object as PropType<StatementModel>, required: true}
    },
    setup(props) {
        const store = useStore(vuexStoreKey);

        const years = props.statement.getYears();

        const onRate = (rating: Rating, forYear: number) => {
            store.commit('setAnswer', {
                uuid: props.statement.uuid,
                rating: rating,
                year: forYear,
            });
        }

        return {years, onRate, Rating};
    },
    computed: {
        selectedAnswer(): Rating | null {
            return null;
        }
    }
})
