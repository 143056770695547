
import {defineComponent, PropType} from 'vue'
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import {KnowledgebaseItem} from '@/data/api';

export default defineComponent({
	name: 'ResultBlockKnowledgeItems',
	components: {KnowledgebaseBlock},
	props: {
		content: {type: Array as PropType<KnowledgebaseItem[]>, required: true}
	}
})
