<template>
    <div class="competence-result-navigation">
        <div class="competence-result-navigation__inner d-flex flex-column w-100" :class="{'show': isOpen}">
            <CompetenceNavItem
                v-for="(comp, key) in competencesToDisplay"
                :key="key" :to="`/scan/resultaten/${comp.id}`"
                class="router-resultaat-competentie"
                :selected="isSelected(comp)"
                :url="`/scan/resultaten/${comp.id}`"
                @btn-click="isOpen = !isOpen"
            >
                <RatingSymbol :rating="Math.round(comp.getRating($store.state.answers, 1))"/>
                <div class="competence-result-navigation__content">
                    {{ comp.name }}
                </div>
            </CompetenceNavItem>
        </div>

        <button class="more-info-stand__button" :class="{'show': isOpen}" @click="isOpen = !isOpen">
            <span><Icon icon="chevronDown"/></span>
        </button>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {competenceMixin} from '@/router/competenceMixin';
import RatingSymbol from '@/components/RatingSymbol.vue';
import Icon from '@/components/Icon.vue';
import CompetenceNavItem from '@/components/CompetenceNavItem.vue';
import {CompetenceModel} from '@/data/CompetenceModel';

export default defineComponent({
	name: 'CompetenceResultNav',
	components: {CompetenceNavItem, RatingSymbol, Icon},
	mixins: [competenceMixin],
	data() {
		return {
			isOpen: false
		}
	},
	computed: {
		competencesToDisplay(): CompetenceModel[] {
			const activeCompetence = this.competence ? [this.competence] : [];
			return this.isOpen ? this.$store.state.competences ?? [] : activeCompetence;
		}
	},
	watch: {
		$route() {
			this.isOpen = false;
		},
	},
	methods: {
		isSelected(competence: CompetenceModel) {
			return competence.id === this.competence?.id;
		},
	}
})
</script>