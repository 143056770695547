import {Rating} from '@/data/enums';
import {LocationAsRelativeRaw} from 'vue-router';
import {CompetenceModel} from '@/data/CompetenceModel';

// Any state values than rely and api's should initially be null
// That way we can easily tell if an api call failed or has not happened yet
export const state = {
    yearsOfExperience: 0,
    answers: [{}, {}, {}, {}] as {[key: string]: Rating}[],
    competences: null as CompetenceModel[]|null,
    steps: [] as LocationAsRelativeRaw[],
    startedScan: false
};

export type State = typeof state;

// Persistent data will be saved to localstorage
export const persistent: readonly (keyof State)[] = ['answers', 'startedScan', 'yearsOfExperience'];
